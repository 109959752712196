import {useEffect, useState} from 'react';
import {
    getActiveUsersPerCourseAPI,
    getActiveUsersOverTimeAPI,
} from './utils/activeUsers';  // Replace with the correct path

const useActiveUsers = () => {
    const [activeUsersPerCourse, setActiveUsersPerCourse] = useState([]);
    const [activeUsersPerCourseLoaded, setActiveUsersPerCourseLoaded] = useState(false);

    const [activeUsersOverTime, setActiveUsersOverTime] = useState([]);
    const [activeUsersOverTimeLoaded, setActiveUsersOverTimeLoaded] = useState(false);

    useEffect(() => {
        getActiveUsersPerCourseAPI((data) => {
            setActiveUsersPerCourse(data);
            setActiveUsersPerCourseLoaded(true);
        });

        getActiveUsersOverTimeAPI((data) => {
            setActiveUsersOverTime(data);
            setActiveUsersOverTimeLoaded(true);
        });
    }, []);

    return {
        activeUsersPerCourse,
        activeUsersPerCourseLoaded,
        activeUsersOverTime,
        activeUsersOverTimeLoaded,
    }


};

export default useActiveUsers;
