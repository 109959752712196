import { useTranslation } from 'react-i18next';

export default function ActivityController() {
  const { t } = useTranslation();

  return (
    <div>
      <h1>{t('Activity Controller')}</h1>
      <p>{t('This is the activity controller component for managing tasks.')}</p>
      <button>{t('Submit')}</button>
    </div>
  );
}