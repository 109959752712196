import React from "react";
import Coursecompletion from "../../../components/Coursecompletion/Coursecompletion";
import './CourseCompletionViewContent.css'
import Descriptivepagetitle from "../../../components/Descriptivepagetitle/Descriptivepagetitle";
import { useTranslation } from "react-i18next";

const CourseCompletionViewContent = () => {
    const { t } = useTranslation();
    return (
        <>
          <div className="p-row r-r">
            <div className="div-block-204 l">
              <div className="div-block-183">
                  <Descriptivepagetitle title={t("DESCRIPTIVE DATA")}/>
              </div>
            </div>
            {/*<div className="div-block-204 r">
              <div className="div-block-183">
                  <Descriptivepagetitle isPredictive={true} title={"PREDICTIVE DATA"}/>
              </div>
            </div>*/}
          </div>
          <Coursecompletion></Coursecompletion>
        </>

    )
}

export default CourseCompletionViewContent;