import React from 'react';
import './Timespent.css';
import useTimeSpent from '../../hooks/useTimeSpent';
import DescriptiveLineChartGraph from '../DescriptiveLineChartGraph/DescriptiveLineChartGraph';
import Timespenttable from '../Timespenttable/Timespenttable';
import { getEngagementTimeSpentPerCourseAPI } from '../../hooks/utils/timeSpent';
import { useTranslation } from 'react-i18next';

const Timespent = (props) => {
  const [
    engagementTimeSpentPerCourse,
    engagementTimeSpentPerCourseLoaded,
    engagementTimeSpentOvertime,
    engagementTimeSpentOvertimeLoaded,
  ] = useTimeSpent();
  const { t } = useTranslation();

  return (
    <div
      react-hook="useTimeSpent"
      react-hook-return="timeSpentTotal,timeSpentLoaded,timeSpentChangeClass,timeSpentChangeSign,timeSpentNumericalChange,timeSpentPercentageChange,timeSpentChangeSymbol,timeSpentChartData,predictedTimeCompletionTotal,predictedTimeCompletionLoaded,predictedTimeCompletionChangeClass,predictedTimeCompletionChangeSign,predictedTimeCompletionNumericalChange,predictedTimeCompletionPercentageChange,predictedTimeCompletionChangeSymbol,predictedTimeCompletionChartData,timeSpentPerCourseCompletionSelect,timeSpentPerCourseCompletionSearch,timeSpentPerCourseCompletionTableData,timeSpentPerCourseCompletionCurrentPage,timeSpentPerCourseCompletionItemsPerPage,timeSpentPerCourseCompletionTotalItems"
    >
      <div className="p-row r-r">
        <div className="div-block-204 l" style={{ width: '100%' }}>
          <DescriptiveLineChartGraph
            data={engagementTimeSpentOvertime}
            dataLoaded={engagementTimeSpentOvertimeLoaded}
            title={'Time Spent'}
            index={1}
            convertTime={true}
          />
        </div>
      </div>
      <Timespenttable
        endPoint={getEngagementTimeSpentPerCourseAPI}
        title={'Time Spent Per Course'}
      ></Timespenttable>
    </div>
  );
};

export default Timespent;
