import React, { useState, useEffect } from 'react';
import './Coursetable.css';
import { IBLSpinLoader } from '@iblai/ibl-web-react-common';
import EmptyTableDataInfographic from '../EmptyTableDataInfographic/EmptyTableDataInfographic';
import TablePagination from '../TablePagination/TablePagination';
import {
  getRamdomCourseImg,
  getTableSizes,
  getUserCoursesMetaData,
} from '../../hooks/helpers';
import { useDebounce } from '../../hooks/useDebounce';
import TableSizeDropdown from '../TableSizeDropdown/TableSizeDropdown';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const TABLE_SIZES = getTableSizes();

const Coursetable = ({
  useSizeDropdown = true,
  endPoint,
  dataKey = 'data',
  title,
  tableHeaders = [
    '#',
    'Course',
    'Registered Users',
    'Percentage of All Courses',
  ],
}) => {
  const [data, setData] = useState({});
  const [rawData, setRawData] = useState({});
  const [dataLoaded, setDataLoaded] = useState(false);
  const [search, setSearch] = useState('');
  const [size, setSize] = useState(TABLE_SIZES[0]);
  const [page, setPage] = useState(1);
  const [dataHasLoadedOnce, setDataHasLoadedOnce] = useState(false);
  const [courseImages, setCourseImages] = useState({});
  const [imageUrl, setImageUrl] = useState(getRamdomCourseImg());
  const { t } = useTranslation();

  const handleImageError = (e) => {
    e.target.src = imageUrl;
  };

  const getCourseImage = (courseId) => {
    return new Promise((resolve, reject) => {
      getUserCoursesMetaData(courseId, (metaData) => {
        const courseImage = metaData?.course_image_asset_path;
        if (courseImage) {
          resolve(`${process.env.REACT_APP_IBL_LMS_URL}${courseImage}`);
        } else {
          resolve(getRamdomCourseImg());
        }
      });
    }).catch((error) => {
      console.error(error);
      return getRamdomCourseImg();
    });
  };

  const fetchCourseImages = (courses) => {
    courses.forEach((course) => {
      const courseId = course.course_id || course.course;
      if (!courseImages[courseId]) {
        getCourseImage(courseId).then((image) => {
          setCourseImages((prevImages) => ({
            ...prevImages,
            [courseId]: image,
          }));
        });
      }
    });
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
  };

  const handleLoadData = () => {
    setDataLoaded(false);
    endPoint(
      {
        length: size,
        page,
      },
      (data) => {
        setRawData(data);
        setData(data?.[dataKey]);
        setDataLoaded(true);
        setDataHasLoadedOnce(true);
      }
    );
  };

  useDebounce(handleLoadData, 300, [page, size]);

  useDebounce(
    () => {
      if (dataHasLoadedOnce) {
        if (search === '') {
          setData(rawData?.[dataKey]);
        } else {
          setData(
            Array.isArray(rawData?.[dataKey])
              ? rawData?.[dataKey].filter((el) =>
                  String(el.name || el.course)
                    .toUpperCase()
                    .includes(String(search).toUpperCase())
                )
              : []
          );
        }
      }
    },
    300,
    [search]
  );

  useEffect(() => {
    if (Array.isArray(rawData?.[dataKey])) {
      fetchCourseImages(rawData?.[dataKey], courseImages, setCourseImages);
    }
  }, [rawData]);

  return (
    <div className="table_cont mgt0">
      <div className="div-block-202 w_t w_w">
        <div className="div-block-169">
          <div>
            <div className="text-block-109">{title}</div>
          </div>
        </div>
        <div className="div-block-169">
          <div
            className="div-block-171"
            style={{ visibility: useSizeDropdown ? 'visible' : 'hidden' }}
          >
            <div className="sm">Show</div>
            <div className="form-block w-form">
              <TableSizeDropdown setSize={setSize} setPage={setPage} />
              <div className="w-form-done">
                <div>{t('Thank you! Your submission has been received!')}</div>
              </div>
              <div className="w-form-fail">
                <div>
                  {t('Oops! Something went wrong while submitting the form.')}
                </div>
              </div>
            </div>
            <div className="sm">{t('entries')}</div>
          </div>
          <div className="div-block-171">
            <div className="sm">Search:</div>
            <div className="form-block w-form">
              <form
                data-name="Email Form 2"
                data-wf-element-id="cf68b144-b8b0-58ee-bf65-6b8d2725cae6"
                data-wf-page-id="657af3ad1367da03b3b12581"
                id="email-form-2"
                method="get"
                onSubmit={handleFormSubmit}
                name="email-form-2"
              >
                <input
                  className="input w-input"
                  data-name="Field 3"
                  id="field-3"
                  maxLength={256}
                  name="field-3"
                  placeholder=""
                  required=""
                  type="text"
                  value={search}
                  onChange={(e) => {
                    setSearch(e?.target?.value);
                  }}
                />
              </form>
              <div className="w-form-done">
                <div>{t('Thank you! Your submission has been received!')}</div>
              </div>
              <div className="w-form-fail">
                <div>
                  {t('Oops! Something went wrong while submitting the form.')}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="table">
          <div className="th">
            <div className="tc _10">
              <div className="sm">{tableHeaders[0]}</div>
            </div>
            <div className="tc">
              <div className="w_sort">
                <div className="text-block-134 fa_icon sort"></div>
                <div className="sm">{tableHeaders[1]}</div>
              </div>
            </div>
            <div className="tc _32-5">
              <div className="w_sort">
                <div className="text-block-134 fa_icon sort"></div>
                <div className="sm">{tableHeaders[2]}</div>
              </div>
            </div>
            <div className="tc _32-5">
              <div className="w_sort">
                <div className="text-block-134 fa_icon sort"></div>
                <div className="sm">{tableHeaders[3]}</div>
              </div>
            </div>
          </div>
          {
            <>
              {!dataLoaded ? (
                <IBLSpinLoader
                  color={'#FFF'}
                  size={20}
                  containerHeight={'200px'}
                />
              ) : Array.isArray(data) && data.length > 0 ? (
                data.map((course, index) => (
                  <div className="tr" key={course.course_id}>
                    <div className="tc tcell _10">
                      <div className="m">{index + 1}</div>
                    </div>
                    <div className="tc tcell">
                      <img
                        src={
                          courseImages[course.course_id || course.course] ||
                          imageUrl
                        }
                        onError={handleImageError}
                        alt=""
                        className="r-i"
                        loading="lazy"
                      />
                      <Link
                        className="tl-2 np"
                        to={`${process.env.REACT_APP_IBL_SPA_SKILLS_URL}/courses/${course.course_id || course.course}`}
                        target={'_blank'}
                      >
                        <span className="nb" prop-coursetitle="">
                          {course?.course_name ||
                            course?.name ||
                            course?.course}
                        </span>
                      </Link>
                    </div>
                    <div className="tc tcell _32-5">
                      <div className="m">
                        {course.users ||
                          course.enrollments ||
                          course.time_spent ||
                          course.credential_count}
                      </div>
                    </div>
                    <div className="tc tcell _32-5">
                      <div className="w_per">
                        <div className="p_text">
                          {course.percentage !== undefined &&
                          course.percentage !== null
                            ? course.percentage
                            : course.average !== undefined &&
                                course.average !== null
                              ? course.average
                              : course.percentage_of_total_users !==
                                    undefined &&
                                  course.percentage_of_total_users !== null
                                ? course.percentage_of_total_users
                                : '0'}
                          %
                        </div>
                        <div className="div-block-177">
                          <div
                            className="div-block-203 ph"
                            style={{
                              width: `${
                                course.percentage !== undefined &&
                                course.percentage !== null
                                  ? course.percentage
                                  : course.average !== undefined &&
                                      course.average !== null
                                    ? course.average
                                    : course.percentage_of_total_users !==
                                          undefined &&
                                        course.percentage_of_total_users !==
                                          null
                                      ? course.percentage_of_total_users
                                      : 0 // Default value if none of the above are defined
                              }%
`,
                            }}
                          ></div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <EmptyTableDataInfographic />
              )}
              {!!rawData?.pagination && !!rawData?.pagination?.totalItems && (
                <TablePagination
                  pagination={rawData?.pagination}
                  page={page}
                  setPage={setPage}
                />
              )}
            </>
          }
        </div>
      </div>
    </div>
  );
};

export default Coursetable;
