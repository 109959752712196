import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import frTranslations from '@iblai/ibl-web-react-common/i18n/fr/library.json'
import enTranslations from '@iblai/ibl-web-react-common/i18n/en/library.json'
import cnTranslations from '@iblai/ibl-web-react-common/i18n/zh-CN/library.json'
import esTranslations from '@iblai/ibl-web-react-common/i18n/es/library.json'
import arTranslations from '@iblai/ibl-web-react-common/i18n/ar/library.json'

i18n.use(Backend).use(LanguageDetector).use(initReactI18next).init({
  fallbackLng: 'en',
  ns: ['translation', 'library'], 
  defaultNS: 'translation',
  backend: {
    loadPath: '/locales/{{lng}}/{{ns}}.json', // Adjust the path to load translations
  },
  interpolation: {
    escapeValue: false // React already escapes values
  }
});

i18n.addResourceBundle('en', 'library', enTranslations);
i18n.addResourceBundle('fr', 'library', frTranslations);
i18n.addResourceBundle('es', 'library', esTranslations);
i18n.addResourceBundle('zh-CN', 'library', cnTranslations);
i18n.addResourceBundle('ar', 'library', arTranslations);

export default i18n;
