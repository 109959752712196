import React from "react";
import Unenrollments from "../../../components/Unenrollments/Unenrollments";
import "./UnenrollmentsViewContent.css"
import Descriptivepagetitle from "../../../components/Descriptivepagetitle/Descriptivepagetitle";
import { useTranslation } from "react-i18next";


const UnenrollmentsViewContent = () => {
    const { t } = useTranslation();
    return (
        <>
          <div className="p-row r-r">
            <div className="div-block-204 l">
              <div className="div-block-183">
                  <Descriptivepagetitle title={t("DESCRIPTIVE DATA")}/>
              </div>
            </div>
            {/*<div className="div-block-204 r">
              <div className="div-block-183">
                  <Descriptivepagetitle isPredictive={true} title={"PREDICTIVE DATA"}/>
              </div>
            </div>*/}
          </div>
          <Unenrollments></Unenrollments>
        </>
    )
}

export default UnenrollmentsViewContent;