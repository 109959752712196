import { getTenant, getUserName } from '../helpers';
import { api } from '@iblai/ibl-web-react-common';

export function getCredentialsIssued(callback) {
  api.iblaxdApiCallWrapper(
    (args) =>
      api.iblaxdcredapi.credentialsOrgsUsersAssertionsOverTimeRetrieve(...args),
    getTenant(),
    getUserName(),
    {}
  )((err, data, response) => (err, callback(data), response));
}

export function getCredentialsPerCourse(additionalData, callback) {
  api.iblaxdApiCallWrapper(
    (args) =>
      api.iblaxdcredapi.credentialsOrgsUsersCourseCredentialsList(...args),
    getTenant(),
    getUserName(),
    {
      ...additionalData,
    }
  )((err, data, response) => (err, data, callback(response.body)));
}
