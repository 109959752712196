import { useEffect, useState } from 'react';
import {
    getEngagementTimeSpentPerCourseAPI,
    getEngagementTimeSpentOvertimeAPI,
} from './utils/timeSpent';  // Replace with the correct path

const useTimeSpent = () => {
    const [engagementTimeSpentPerCourse, setEngagementTimeSpentPerCourse] = useState([]);
    const [engagementTimeSpentPerCourseLoaded, setEngagementTimeSpentPerCourseLoaded] = useState(false);

    const [engagementTimeSpentOvertime, setEngagementTimeSpentOvertime] = useState([]);
    const [engagementTimeSpentOvertimeLoaded, setEngagementTimeSpentOvertimeLoaded] = useState(false);

    useEffect(() => {
        /*getEngagementTimeSpentPerCourseAPI(1, (currentPage, data) => {
            setEngagementTimeSpentPerCourse(data);
            setEngagementTimeSpentPerCourseLoaded(true);
        });*/

        getEngagementTimeSpentOvertimeAPI((data) => {
            setEngagementTimeSpentOvertime(data);
            setEngagementTimeSpentOvertimeLoaded(true);
        });
    }, []);

    return [
        engagementTimeSpentPerCourse,
        engagementTimeSpentPerCourseLoaded,
        engagementTimeSpentOvertime,
        engagementTimeSpentOvertimeLoaded,
    ]
};

export default useTimeSpent;
