import {useEffect, useState} from 'react';
import {getLicensingUserGroupList} from "./utils/licensing";
import {useDebounce} from "./useDebounce";  // Replace with the correct path

const useLicensingGroup = ({search="", page=1, size=10, refresher=null}) => {
    const [groups, setGroups] = useState([]);
    const [groupLoading, setGroupLoading] = useState(false);

    const handleGroupListRetrieval = (data) => {
        setGroups(Array.isArray(data?.results) ? data.results : [])
        setGroupLoading(false)
    }

    useDebounce(()=>{
        setGroupLoading(true)
        getLicensingUserGroupList( {
            page_size:size,
            page,
            ...(!!search ? {
                query:search
            } : {})
        }, handleGroupListRetrieval, handleGroupListRetrieval)
    }, 200, [search, page, size, refresher])

    return {
        groups,
        groupLoading,
    }


};

export default useLicensingGroup;
