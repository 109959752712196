import React, { useEffect, useRef, useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { getPerLearnerUsers } from '../../hooks/utils/perLearner';
import { useDebounce } from '../../hooks/useDebounce';
import { IBLSpinLoader } from '@iblai/ibl-web-react-common';
import $ from 'jquery';
import './SearchBar.css';
import { useTranslation } from 'react-i18next';

const SearchBar = () => {
  const [search, setSearch] = useState('');
  const [searchedUsers, setSearchedUsers] = useState([]);
  const [searchedUsersLoading, setSearchedUsersLoading] = useState(false);
  const searchAutoCompleteBoxRef = useRef(null);
  const inputRef = useRef(null);
  const spanRef = useRef(null);
  const { t } = useTranslation();

  useEffect(() => {
    if (spanRef.current) {
      spanRef.current.textContent = search || ' ';
      if (inputRef.current) {
        inputRef.current.style.width = `${spanRef.current.offsetWidth + 30}px`; // Adding some padding
      }
    }
  }, [search]);

  const handlePerLearnersLoad = () => {
    searchAutoCompleteBoxRef.current.style.display = 'none';
    if (search === '' || search.length < 3) {
      setSearchedUsersLoading(false);
      setSearchedUsers([]);
    } else {
      setSearchedUsersLoading(true);
      getPerLearnerUsers(search, 10, (data) => {
        const updatedData = Array.isArray(data?.data) ? data.data : [];
        setSearchedUsers(updatedData);
        if (updatedData.length > 0) {
          searchAutoCompleteBoxRef.current.style.display = 'block';
        }
        setSearchedUsersLoading(false);
      });
    }
  };

  const handleAutoCompleteDropdownClose = () => {
    //HIDING DROPDOWN WHEN CLICKING ELSEWHERE
    $(document).on('click', function (e) {
      if (
        $('.header-search-bar .autocomplete-users:visible').length === 1 &&
        !$(e.target).closest('.header-search-bar .autocomplete-users').length
      ) {
        $('.header-search-bar .autocomplete-users').hide();
      }
    });
  };

  useDebounce(handlePerLearnersLoad, 300, [search]);
  useDebounce(handleAutoCompleteDropdownClose, 200, []);
  return (
    <div className="form-block-4 w-form analytics-searchbar-component">
      <form
        className="form header-search-bar search-bar-form"
        data-name="Email Form 4"
        data-wf-element-id="b549767a-be92-96be-160e-af9a114bf577"
        data-wf-page-id="657af3ad1367da03b3b1262f"
        id="email-form-4"
        name="email-form-4"
        onSubmit={(event) => event.preventDefault()}
        prop-events-value-onsubmit="handleHeaderSearchBarSubmit"
      >
        <div className="div-block-245">
          <input
            ref={inputRef}
            className="text-field-2 w-input"
            style={{ minWidth: '200px' }}
            data-name="Name"
            id="name"
            maxLength={256}
            name="name"
            placeholder=""
            type="text"
            onChange={(e) => setSearch(e?.target?.value)}
          />
          <span ref={spanRef} className="hidden-span"></span>
          <div ref={searchAutoCompleteBoxRef} className="autocomplete-users">
            {searchedUsers.map((user, index) => (
              <Link
                key={index}
                className="user-row"
                to={`/per-learner/learners/${user?.username}`}
                previewlistener="true"
              >
                {user?.full_name || '-'}
              </Link>
            ))}
          </div>

          <div
            onClick={
              !searchedUsersLoading ? () => handlePerLearnersLoad() : () => {}
            }
            className="div-block-246"
          >
            {searchedUsersLoading ? (
              <IBLSpinLoader size={18} color={'#FFF'} />
            ) : (
              <div className="text-block fa_icon mg"></div>
            )}
          </div>
        </div>
      </form>
      <div className="w-form-done">
        <div>{t('Thank you! Your submission has been received!')}</div>
      </div>
      <div className="w-form-fail">
        <div>{t('Oops! Something went wrong while submitting the form.')}</div>
      </div>
    </div>
  );
};

export default SearchBar;
