import React from 'react';
import { useTranslation } from 'react-i18next';

export default function CredentialsController() {
  const { t } = useTranslation();
  
  return (
    <div>
      <h1>{t('Welcome to My App')}</h1>
      <p>{t('Please enter your credentials to proceed.')}</p>
      <input type="text" placeholder={t('Username')} />
      <input type="password" placeholder={t('Password')} />
      <button>{t('Login')}</button>
    </div>
  );
}