import {useEffect, useState} from 'react';
import {
    getUnenrollmentsOverTimeAPI,
    getUnenrollmentsPerCourseAPI,
} from './utils/unenrollments';  // Replace with the correct path

const useUnenrollments = () => {
    const [unenrollmentsOverTime, setUnenrollmentsOverTime] = useState(null);
    const [unenrollmentsOverTimeLoaded, setUnenrollmentsOverTimeLoaded] = useState(false);

    /*const [unenrollmentsPerCourse, setUnenrollmentsPerCourse] = useState([]);
    const [unenrollmentsPerCourseLoaded, setUnenrollmentsPerCourseLoaded] = useState(false);*/

    useEffect(() => {
        getUnenrollmentsOverTimeAPI((data) => {
            setUnenrollmentsOverTime(data);
            setUnenrollmentsOverTimeLoaded(true);
        });

        /*// Assuming you want the first page initially
        getUnenrollmentsPerCourseAPI(1, (data) => {
            setUnenrollmentsPerCourse(data);
            setUnenrollmentsPerCourseLoaded(true);
        });*/
    }, []);

    return [
        unenrollmentsOverTime,
        unenrollmentsOverTimeLoaded,
        /*unenrollmentsPerCourse,
        unenrollmentsPerCourseLoaded,*/
    ]

};

export default useUnenrollments;
