import React from 'react';
import { PopUp } from '../../PopUp/PopUp';
import { InvitationTab } from '../InvitationTab/InvitationTab';
import { InvitationTabContent } from '../InvitationTabContent/InvitationTabContent';
import { actionTypes } from '../../../views/content/InvitationsViewContent/InvitationsReducer';

const InviteUserPopUp = ({ state, dispatch, handlePageChange }) => {
  return (
    <PopUp
      title="Invite User"
      isActive={state.isInviteUserActive}
      actionType={actionTypes.TOGGLE_INVITE_USER}
      dispatch={dispatch}
      state={state}
      handlePageChange={handlePageChange}
    >
      <InvitationTab state={state} dispatch={dispatch} />
      <InvitationTabContent dispatch={dispatch} state={state} />
    </PopUp>
  );
};

export default InviteUserPopUp;
