import { useTranslation } from 'react-i18next';

export default function CredentialController() {
  const { t } = useTranslation();

  return (
    <div>
      <h1>{t('Welcome to the Credential Controller')}</h1>
      <p>{t('Please enter your credentials to continue')}</p>
      <button>{t('Sign In')}</button>
      <p>{t('Forgot password?')}</p>
    </div>
  );
}