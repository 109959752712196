import { api } from '@iblai/ibl-web-react-common';
import { getTenant, getTodayFormattedDate } from '../helpers';

export function getUnenrollmentsOverTimeAPI(callback) {
  api.iblaxdApiCallWrapper(
    (args) =>
      api.iblaxdaudience.audienceOrgsEnrollmentsOverTimeRetrieve(...args),
    getTenant(),
    {
      active: 'false',
      endDate: getTodayFormattedDate(),
      startDate: 'BEGINNING',
    }
  )((err, data, response) => (err, callback(data), response));
}

export function getUnenrollmentsPerCourseAPI(additionalData, callback) {
  api.iblaxdApiCallWrapper(
    (args) =>
      api.iblaxdaudience.audienceOrgsRegisteredUsersPerCourseRetrieve(...args),
    getTenant(),
    {
      ...additionalData,
      active: 'false',
    }
  )((err, data, response) => (err, callback(data), response));
}
