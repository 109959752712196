import { api } from '@iblai/ibl-web-react-common';
import { beginningDateRange, getTenant } from '../helpers';

export function getActiveUsersPerCourseAPI(callback) {
  api.iblaxdApiCallWrapper(
    (args) =>
      api.iblaxdaudience.audienceOrgsActiveUsersPerCourseRetrieve(...args),
    getTenant(),
    beginningDateRange
  )((err, data, response) => (err, callback(data), response));
}

export function getActiveUsersOverTimeAPI(callback) {
  api.iblaxdApiCallWrapper(
    (args) =>
      api.iblaxdaudience.audienceOrgsActiveUsersOverTimeRetrieve(...args),
    getTenant(),
    beginningDateRange
  )((err, data, response) => (err, callback(data), response));
}
