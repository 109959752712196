import { api } from '@iblai/ibl-web-react-common';
import { getTenant } from '../helpers';

export function getDownloadsListAPI(callback) {
  api.iblaxdApiCallWrapper(
    (args) => api.iblaxdreportapi.reportsOrgsList(...args),
    getTenant()
  )((err, data, response) => (err, data, callback(response?.body?.data)));
}

export function createNewReport(reportData, callback) {
  api.iblaxdApiCallWrapper(
    (args) => api.iblaxdreportapi.reportsOrgsNewCreate(...args),
    getTenant(),
    {
      reportRequest: { ...reportData },
    }
  )((err, data, response) => (err, data, callback(response)));
}
