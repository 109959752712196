import React, {useEffect, useState} from "react";
import Linechartgraph from "../../../components/Linechartgraph/Linechartgraph";
import useActiveUsers from "../../../hooks/useActiveUsers";
import DescriptiveLineChartGraph from "../../../components/DescriptiveLineChartGraph/DescriptiveLineChartGraph";
import Verticalgraphcard from "../../../components/Verticalgraphcard/Verticalgraphcard";
import './ActiveUsersViewContent.css'
import Descriptivepagetitle from "../../../components/Descriptivepagetitle/Descriptivepagetitle";
import {randomLineChartGraphData, randomVerticalGraphData} from "../../../hooks/helpers";
import { useTranslation } from "react-i18next";

const ActiveUsersViewContent = () => {
    const {activeUsersPerCourse,
        activeUsersPerCourseLoaded,
        activeUsersOverTime,
        activeUsersOverTimeLoaded} = useActiveUsers();
    const { t } = useTranslation();
    return (
        <>

          <div className="p-row r-r">
            <div className="div-block-204 l">
              <div className="div-block-183">
                <Descriptivepagetitle title={t("DESCRIPTIVE DATA")}/>
              </div>
            </div>
            {/*<div className="div-block-204 r">
              <div className="div-block-183">
                <Descriptivepagetitle isPredictive={true} title={"PREDICTIVE DATA"}/>
              </div>
            </div>*/}
          </div>
          <div
              className="p-row r-r"
              react-hook="useRegisteredUsers"
              react-hook-return="activeUsersOverTimeTotal,activeUsersOverTimeLoaded,activeUsersOverTimeChangeClass,activeUsersOverTimeChangeSign,activeUsersOverTimeNumericalChange,activeUsersOverTimePercentageChange,activeUsersOverTimeChangeSymbol,activeUsersOverTimeChartData,predictedActiveUsersTotal,predictedActiveUsersLoaded,predictedActiveUsersChangeClass,predictedActiveUsersChangeSign,predictedActiveUsersNumericalChange,predictedActiveUsersPercentageChange,predictedActiveUsersChangeSymbol,predictedActiveUsersChartData,mostActiveUsersPerCourseTotal,mostActiveUsersPerCourseLoaded,mostActiveUsersPerCourseChangeClass,mostActiveUsersPerCourseChangeSign,mostActiveUsersPerCourseNumericalChange,mostActiveUsersPerCoursePercentageChange,mostActiveUsersPerCourseChangeSymbol,mostActiveUsersPerCoursetData,predictedMostActiveUsersPerCourseTotal,predictedMostActiveUsersPerCourseLoaded,predictedMostActiveUsersPerCourseChangeClass,predictedMostActiveUsersPerCourseChangeSign,predictedMostActiveUsersPerCourseNumericalChange,predictedMostActiveUsersPerCoursePercentageChange,predictedMostActiveUsersPerCourseChangeSymbol,predictedMostActiveUsersPerCoursetData"
              reactcomponent="ActiveUsers"
          >
            <div className="div-block-204 l" style={{width:"100%"}}>
              <DescriptiveLineChartGraph
                  data={activeUsersOverTime}
                  dataLoaded={activeUsersOverTimeLoaded}
                  title={"Active Users Over Time"}
                  index={1}
              />
              <div className="table_cont hidden">
                <div className="div-block-202">
                  <div className="div-block-169">
                    <div>
                      <div className="text-block-109">
                        {t('Most Active Courses | Active Users per Course')}
                        <br/>
                        <br/>
                      </div>
                    </div>
                  </div>
                  <div className="div-block-169">
                    <div className="div-block-171">
                      <div className="sm">{t('Show')}</div>
                      <div className="form-block w-form">
                        <form
                            data-name="Email Form 2"
                            data-wf-element-id="eb9b50b6-a9ad-147a-5531-27803d878f93"
                            data-wf-page-id="657af3ad1367da03b3b1255d"
                            id="email-form-2"
                            method="get"
                            name="email-form-2"
                        >
                          <select
                              className="show w-select"
                              data-name="Field 2"
                              id="field-2"
                              name="field-2"
                          >
                            <option value="">10</option>
                            <option value="First">25</option>
                            <option value="Second">50</option>
                            <option value="Third">100</option>
                          </select>
                        </form>
                        <div className="w-form-done">
                          <div>
                            {t('Thank you! Your submission has been received!')}
                          </div>
                        </div>
                        <div className="w-form-fail">
                          <div>
                            {t('Oops! Something went wrong while submitting the form.')}
                          </div>
                        </div>
                      </div>
                      <div className="sm">{t('entries')}</div>
                    </div>
                    <div className="div-block-171">
                      <div className="sm">Search:</div>
                      <div className="form-block w-form">
                        <form
                            data-name="Email Form 2"
                            data-wf-element-id="eb9b50b6-a9ad-147a-5531-27803d878fa1"
                            data-wf-page-id="657af3ad1367da03b3b1255d"
                            id="email-form-2"
                            method="get"
                            name="email-form-2"
                        >
                          <input
                              className="input w-input"
                              data-name="Field 3"
                              id="field-3"
                              maxLength={256}
                              name="field-3"
                              placeholder=""
                              required=""
                              type="text"
                          />
                        </form>
                        <div className="w-form-done">
                          <div>
                            {t('Thank you! Your submission has been received!')}
                          </div>
                        </div>
                        <div className="w-form-fail">
                          <div>
                            {t('Oops! Something went wrong while submitting the form.')}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="table">
                    <div className="th">
                      <div className="tc">
                        <div className="sm">#</div>
                      </div>
                      <div className="tc">
                        <div className="w_sort">
                          <div className="text-block-134 fa_icon sort">
                            
                          </div>
                          <div className="sm">{t('Course')}</div>
                        </div>
                      </div>
                      <div className="tc">
                        <div className="w_sort">
                          <div className="text-block-134 fa_icon sort">
                            
                          </div>
                          <div className="sm">{t('Active Users')}</div>
                        </div>
                      </div>
                      <div className="tc">
                        <div className="w_sort">
                          <div className="text-block-134 fa_icon sort">
                            
                          </div>
                          <div className="sm">
                            {t('Percentage of All Courses')}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="tr">
                      <div className="tc tcell">
                        <div className="m">1</div>
                      </div>
                      <div className="tc tcell">
                        <a className="tl-2 np" href="#">
                          English
                        </a>
                      </div>
                      <div className="tc tcell">
                        <div className="m">22131</div>
                      </div>
                      <div className="tc tcell">
                        <div className="w_per">
                          <div className="p_text">43%</div>
                          <div className="div-block-177">
                            <div className="div-block-203"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="tr">
                      <div className="tc tcell">
                        <div className="m">2</div>
                      </div>
                      <div className="tc tcell">
                        <a className="tl-2 np" href="#">
                          Physics
                        </a>
                      </div>
                      <div className="tc tcell">
                        <div className="m">11521</div>
                      </div>
                      <div className="tc tcell">
                        <div className="w_per">
                          <div className="p_text">57%</div>
                          <div className="div-block-177">
                            <div className="div-block-203 ph"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="tf">
                      <div className="div-block-176">
                        <div className="info-2">
                          {t('Showing 1 to 2 of 2 entries')}
                        </div>
                        <a className="tl-2" href="#">
                          1
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <Verticalgraphcard
                  data={activeUsersPerCourse}
                  dataLoaded={activeUsersPerCourseLoaded}
                  index={1}
                  title={t("Most Active Courses | Active Users per Course")}
                  label={t("Active Users")}/>
            </div>
            {/*<div className="div-block-204 r">
              <DescriptiveLineChartGraph
                  data={randomLineChartGraphData()}
                  dataLoaded={tempLoadedState}
                  title={"Predicted Active Users"}
                  index={2}
                  isPredictive={true}/>
              <div className="table_cont hidden">
                <div className="div-block-202 prt">
                  <div className="div-block-169">
                    <div>
                      <div className="text-block-109">
                        Predicted Most Active Courses | Predicted
                        Active Users per Course
                        <br/>
                        <br/>
                      </div>
                    </div>
                  </div>
                  <div className="div-block-169">
                    <div className="div-block-171">
                      <div className="sm">Show</div>
                      <div className="form-block w-form">
                        <form
                            data-name="Email Form 2"
                            data-wf-element-id="eb9b50b6-a9ad-147a-5531-27803d87903e"
                            data-wf-page-id="657af3ad1367da03b3b1255d"
                            id="email-form-2"
                            method="get"
                            name="email-form-2"
                        >
                          <select
                              className="show w-select"
                              data-name="Field 2"
                              id="field-2"
                              name="field-2"
                          >
                            <option value="">10</option>
                            <option value="First">25</option>
                            <option value="Second">50</option>
                            <option value="Third">100</option>
                          </select>
                        </form>
                        <div className="w-form-done">
                          <div>
                            Thank you! Your submission has been
                            received!
                          </div>
                        </div>
                        <div className="w-form-fail">
                          <div>
                            Oops! Something went wrong while
                            submitting the form.
                          </div>
                        </div>
                      </div>
                      <div className="sm">entries</div>
                    </div>
                    <div className="div-block-171">
                      <div className="sm">Search:</div>
                      <div className="form-block w-form">
                        <form
                            data-name="Email Form 2"
                            data-wf-element-id="eb9b50b6-a9ad-147a-5531-27803d87904c"
                            data-wf-page-id="657af3ad1367da03b3b1255d"
                            id="email-form-2"
                            method="get"
                            name="email-form-2"
                        >
                          <input
                              className="input w-input"
                              data-name="Field 3"
                              id="field-3"
                              maxLength={256}
                              name="field-3"
                              placeholder=""
                              required=""
                              type="text"
                          />
                        </form>
                        <div className="w-form-done">
                          <div>
                            Thank you! Your submission has been
                            received!
                          </div>
                        </div>
                        <div className="w-form-fail">
                          <div>
                            Oops! Something went wrong while
                            submitting the form.
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="table">
                    <div className="th">
                      <div className="tc">
                        <div className="sm">#</div>
                      </div>
                      <div className="tc">
                        <div className="w_sort">
                          <div className="text-block-134 fa_icon sort">
                            
                          </div>
                          <div className="sm">Course</div>
                        </div>
                      </div>
                      <div className="tc">
                        <div className="w_sort">
                          <div className="text-block-134 fa_icon sort">
                            
                          </div>
                          <div className="sm">Predicted Users</div>
                        </div>
                      </div>
                      <div className="tc">
                        <div className="w_sort">
                          <div className="text-block-134 fa_icon sort">
                            
                          </div>
                          <div className="sm">
                            Percentage of All Courses
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="tr">
                      <div className="tc tcell">
                        <div className="m">1</div>
                      </div>
                      <div className="tc tcell">
                        <a className="tl-2 np prb" href="#">
                          English
                        </a>
                      </div>
                      <div className="tc tcell">
                        <div className="m">25414</div>
                      </div>
                      <div className="tc tcell">
                        <div className="w_per">
                          <div className="p_text">43%</div>
                          <div className="div-block-177">
                            <div className="div-block-203 prb"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="tr">
                      <div className="tc tcell">
                        <div className="m">2</div>
                      </div>
                      <div className="tc tcell">
                        <a className="tl-2 np prb" href="#">
                          Physics
                        </a>
                      </div>
                      <div className="tc tcell">
                        <div className="m">23103</div>
                      </div>
                      <div className="tc tcell">
                        <div className="w_per">
                          <div className="p_text">57%</div>
                          <div className="div-block-177">
                            <div className="div-block-203 ph prb"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="tf">
                      <div className="div-block-176">
                        <div className="info-2">
                          Showing 1 to 2 of 2 entries
                        </div>
                        <a className="tl-2 prb" href="#">
                          1
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <DescriptiveLineChartGraph
                  data={randomLineChartGraphData()}
                  dataLoaded={tempLoadedState}
                  title={"Predicted Most Active Courses | Predicted Active Users per Course"}
                  index={3}
                  isPredictive={true}/>

            </div>*/}
          </div>
        </>
    )
}
export default ActiveUsersViewContent;