import { useEffect, useRef, useState } from 'react';
import { getPlatformInvitations } from './utils/invitations';

const useInvitation = () => {
    const [platformInvitationData, setPlatformInvitationsData] = useState([]);
    const [platformInvitationsDataLoaded, setPlatformInvitationsDataLoaded] =
        useState(false);
    const hasFetchedData = useRef(false);

    useEffect(() => {
        if (!hasFetchedData.current) {
            getPlatformInvitations((data) => {
                setPlatformInvitationsData(data);
                setPlatformInvitationsDataLoaded(true);
            });
            hasFetchedData.current = true;
        }
    }, []);
    return [
        platformInvitationData,
        platformInvitationsDataLoaded,
        setPlatformInvitationsData,
        setPlatformInvitationsDataLoaded,
    ];
};

export default useInvitation;
