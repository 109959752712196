import React from 'react';
import { PopUp } from '../../PopUp/PopUp';
import { RedeemForm } from '../RedeemForm/RedeemForm';
import { actionTypes } from '../../../views/content/InvitationsViewContent/InvitationsReducer';

const RedeemInvitePopUp = ({ state, dispatch, handlePageChange }) => {
  return (
    <PopUp
      title="Redeem Invite"
      isActive={state.isRedeemActive}
      actionType={actionTypes.TOGGLE_REDEEM}
      dispatch={dispatch}
      state={state}
      handlePageChange={handlePageChange}
    >
      <RedeemForm state={state} dispatch={dispatch} />
    </PopUp>
  );
};

export default RedeemInvitePopUp;
