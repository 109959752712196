import React from 'react';
import './Unenrollments.css';
import useUnenrollments from '../../hooks/useUnenrollments';
import DescriptiveLineChartGraph from '../DescriptiveLineChartGraph/DescriptiveLineChartGraph';
import Coursetable from '../Coursetable/Coursetable';
import { getUnenrollmentsPerCourseAPI } from '../../hooks/utils/unenrollments';
import { useTranslation } from 'react-i18next';

const Unenrollments = (props) => {
  const [
    unenrollmentsOverTime,
    unenrollmentsOverTimeLoaded,
    /*unenrollmentsPerCourse,
    unenrollmentsPerCourseLoaded,*/
  ] = useUnenrollments();
  const { t } = useTranslation();
  return (
    <div
      react-hook="useUnenrollments"
      react-hook-return="unenrollmentsOverTimeTotal,unenrollmentsOverTimeLoaded,unenrollmentsOverTimeChangeClass,unenrollmentsOverTimeChangeSign,unenrollmentsOverTimeNumericalChange,unenrollmentsOverTimePercentageChange,unenrollmentsOverTimeChangeSymbol,unenrollmentsOverTimeChartData,predictedunenrollmentsTotal,predictedunenrollmentsLoaded,predictedunenrollmentsChangeClass,predictedunenrollmentsChangeSign,predictedunenrollmentsNumericalChange,predictedunenrollmentsPercentageChange,predictedunenrollmentsChangeSymbol,predictedunenrollmentsChartData,unenrollmentsPerCourseSelect,unenrollmentsPerCourseSearch,unenrollmentsPerCourseTableData,unenrollmentsPerCourseCurrentPage,unenrollmentsPerCourseItemsPerPage,unenrollmentsPerCourseTotalItems"
    >
      <div className="p-row r-r">
        <div className="div-block-204 l" style={{ width: '100%' }}>
          <DescriptiveLineChartGraph
            data={unenrollmentsOverTime}
            dataLoaded={unenrollmentsOverTimeLoaded}
            title={t('Unenrollments Over Time')}
            index={1}
          />
        </div>
        {/*<div className="div-block-204 r">
          <DescriptiveLineChartGraph
              data={randomLineChartGraphData()}
              dataLoaded={tempLoadedState}
              title={"Predicted Unenrollments"}
              index={2}
              isPredictive={true}
          />
        </div>*/}
      </div>
      <Coursetable
        endPoint={getUnenrollmentsPerCourseAPI}
        title={t('Unenrollments per Course')}
      ></Coursetable>
    </div>
  );
};

export default Unenrollments;
