import {useEffect, useState} from 'react';
import {
    assignLicense,
    assignUserToGroup,
    createOrUpdateUserGroup,
    getLicensesList,
    getLicensingUserGroupList,
    getUsersList
} from "./utils/licensing";
import {useDebounce} from "./useDebounce";
import {slugify} from "./helpers";  // Replace with the correct path

const useAssignLicense = ({platformType="user",search="", page=1, size=10}) => {

    const getLicensesForSelectDropdown = (input, callback) => {
        getLicensesList(platformType, {
            page_size:size,
            page,
            ...(input ? {
                query:input
            } : {})
        }, callback, callback)
    }

    const getUserGroupsForSelectDropdown = (input, callback) => {
        getLicensingUserGroupList( {
            page_size:size,
            page,
            ...(input ? {
                query:input
            } : {})
        }, callback, callback)
    }

    const setAssignLicense = (data, callback) =>{
        assignLicense(platformType, data, callback)
    }



    return {
        getLicensesForSelectDropdown,
        getUserGroupsForSelectDropdown,
        setAssignLicense
    }


};

export default useAssignLicense;
