import { actionTypes } from '../../../../views/content/InvitationsViewContent/InvitationsReducer';
import { IBLSpinLoader } from '@iblai/ibl-web-react-common';
import React from 'react';
import { useTranslation } from 'react-i18next';

const itemDataMapping = {
  Program: 'program_id',
  Course: 'course_id',
  Platform: 'program_id',
};

const InvitationInput = ({ state, dispatch, handleInputChange }) => {
  const { t } = useTranslation();
  return (
    <>
      <label htmlFor="name-2" className="text-block-139-bd-f-soc">
        {state.invitationsTab.activeTab}
      </label>
      <input
        className="text-field-3 w-input dynamic-input"
        value={state.selectedItemID}
        onChange={(e) => {
          handleInputChange(e.target.value);
        }}
        name="name-2"
        data-name="Name 2"
        placeholder={`Enter a ${state.invitationsTab.activeTab.toLowerCase()}`}
        type="text"
        id="name-2"
      />
      <div
        className="autocomplete-users"
        style={{
          display: state.searchResults.length === 0 ? 'none' : '',
        }}
      >
        {state.searchResults.map((data) => (
          <button
            key={data._source[itemDataMapping[state.invitationsTab.activeTab]]}
            className="user-row"
            onClick={() => {
              dispatch({
                type: actionTypes.SET_FIELD,
                field: 'selectedItemID',
                value:
                  data._source[itemDataMapping[state.invitationsTab.activeTab]],
              });
              dispatch({
                type: actionTypes.SET_FIELD,
                field: 'searchResults',
                value: [],
              });
              dispatch({
                type: actionTypes.SET_FIELD,
                field: 'itemsLoaded',
                value: true,
              });
            }}
            type="button"
          >
            {data._source.name}
          </button>
        ))}
      </div>
      <div className="div-block-246 custom">
        {state.itemsLoaded ? (
          <div className="text-block fa_icon mg"></div>
        ) : (
          <IBLSpinLoader size={18} color={'#FFF'} />
        )}
      </div>
      <label htmlFor="invite-mode" className="text-block-139-bd-f-soc">
        {state.invitationsTab.activeTab} {t('Invite Mode')}
      </label>
    </>
  );
};

export default InvitationInput;
