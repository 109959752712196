import React from 'react';
import { actionTypes } from '../../../views/content/InvitationsViewContent/InvitationsReducer';
import { useTranslation } from 'react-i18next';

export const RedeemForm = ({ state, dispatch }) => {
  const { t } = useTranslation();
  return (
    <div className="div-block-228">
      <div className="form-block-7 w-form">
        <div
          data-name="Email Form 5"
          data-wf-element-id="dcaa5443-fa45-544f-1705-04f761d8c6c4"
          data-wf-page-id="657aff678982edbdb39ddab6"
          id="email-form-5"
          method="get"
          name="email-form-5"
        >
          <label className="text-block-139-bd-f-soc" htmlFor="name-3">
            {t('User *')}
          </label>
          <input
            className="text-field-3 w-input"
            data-name="Name 2"
            id="name-2"
            maxLength={256}
            name="name-2"
            placeholder="Enter a username or an email"
            type="text"
            value={state.userNameOrEmail}
            onChange={(e) => {
              dispatch({
                type: actionTypes.SET_FIELD,
                field: 'userNameOrEmail',
                value: e.target.value,
              });
            }}
          />
        </div>
      </div>
    </div>
  );
};
