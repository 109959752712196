import React from 'react';
import {CiGlobe} from "react-icons/ci";
import {IBLUserProfile} from "@iblai/ibl-web-react-common";
import './Usermenu.css'
import {getSkillsURL} from "../../hooks/helpers";
import {TbUser} from "react-icons/tb";

const Usermenu = (props) => {
  const SUPPORT_MENU = {
    label: process.env.REACT_APP_IBL_SUPPORT_LINK_LABEL,
    url: process.env.REACT_APP_IBL_SUPPORT_LINK_URL,
  };
  const skillsURL = getSkillsURL()
    return (
        <IBLUserProfile
            className={'analytics-user-profil'}
            style={{minWidth:"50px"}}
            color={'#FFF'}
            backgroundColor={'#21252b'}
            hoverColor={"#383d48"}
            darkMode={true}
            showSettingsMenu={true}
            showProfileMenu={false}
            additionalDropdowns={
            [
                {
                    icon: <TbUser size={18} color={'#FFF'} />,
                    label: 'Profile',
                    to:`${skillsURL}${!String(skillsURL).endsWith('/') ? "/" : ""}profile/public-profile`,
                },
                ...(SUPPORT_MENU.url && SUPPORT_MENU.label ? [
                    {
                        icon: <CiGlobe size={18} color={'#FFF'}/>,
                        label: 'Support',
                        to:SUPPORT_MENU.url,
                        target:'blank'
                    }
                ] : [])
            ]
            }
        />
    );
};

export default Usermenu;