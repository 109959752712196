import React from "react";
import "./Footer.css";
import {defaultSPALogoData} from "../../hooks/helpers";
import logoImg from "../../assets/images/ibl-logos--copy_1.png";
import logoImg500 from "../../assets/images/ibl-logos--copy_1-p-500.png";
import logoImg800 from "../../assets/images/ibl-logos--copy_1-p-800.png";
import logoImg1080 from "../../assets/images/ibl-logos--copy_1-p-1080.png";
import logoImg1600 from "../../assets/images/ibl-logos--copy_1-p-1600.png";
import logoImg2000 from "../../assets/images/ibl-logos--copy_1-p-2000.png";

const Footer = (props) => {
  return (
      <div className="div-block-259">
          <div className="div-block-260 desktop">
              <div className="text-block-157">{props.copyright}</div>
              <a
                  className="link-4"
                  href="about.html"
                  map=""
                  map-value="link"
                  prop-events-value-onclick="handleLinkOnClick"
              >
                  {props.linkLabel}
              </a>
          </div>
          <img
              alt=""
              className="image mob-logo"
              loading="lazy"
              sizes="(max-width: 479px) 100vw, (max-width: 767px) 34vw, (max-width: 991px) 202.1640625px, 100vw"
              src={
                  defaultSPALogoData.logo || logoImg
              }
              srcSet={
                  defaultSPALogoData.logo
                      ? ''
                      : `${logoImg500} 500w, ${logoImg800} 800w, ${logoImg1080} 1080w, ${logoImg1600} 1600w, ${logoImg2000} 2000w`
              }
          />
          <div className="div-block-260 mobile">
              <div className="text-block-157">{props.copyright}</div>
              <a className="link-4" href="about.html">
                  About
              </a>
          </div>
      </div>
  );
};

export default Footer;
