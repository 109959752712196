import {useContext, useEffect, useState} from 'react';
import {
    getGradesPerCourseAPI,
    getAverageCourseGradeAPI,
} from './utils/grading';
import _ from "lodash";
import {getFormattedPredictiveDataset, getPredictiveData} from "./utils/predictive";
import {AppDataContext} from "@iblai/ibl-web-react-common";  // Replace with the correct path

const useGrading = () => {
    const {appData, setAppData} = useContext(AppDataContext);
    /*const [gradesPerCourse, setGradesPerCourse] = useState([]);
    const [gradesPerCourseLoaded, setGradesPerCourseLoaded] = useState(false);*/

    const [averageCourseGrade, setAverageCourseGrade] = useState(null);
    const [averageCourseGradeLoaded, setAverageCourseGradeLoaded] = useState(false);

    const [predictedAverageGrade, setPredictedAverageGrade] = useState({});
    const [predictedAverageGradeLoaded, setPredictedAverageGradeLoaded] = useState(false);

    const is_ai_enabled = process.env.REACT_APP_IBL_ENABLE_AI !== 'false';


    const handleSetPredictiveAverageGradeData = (data, total) => {
        const predictedData = Array.isArray(data) ? data[0]?.predicted_data || {} : {}
        const average = _.ceil(_.sum(Object.values(predictedData)) / (Object.values(predictedData).length))
        setPredictedAverageGrade({
            data: {
                average:average
            }
        })
        setPredictedAverageGradeLoaded(true)
    }

    useEffect(() => {
        // Assuming you have a variable for the current page in your component
        /*const currentPage = 1;  // Replace with your logic to get the current page

        getGradesPerCourseAPI(currentPage, (data) => {
            setGradesPerCourse(data);
            setGradesPerCourseLoaded(true);

        });*/

        getAverageCourseGradeAPI((data) => {
            setAverageCourseGrade(data);
            setAverageCourseGradeLoaded(true);
            if ( is_ai_enabled){
                getPredictiveData(appData?.userData?.user_id, getFormattedPredictiveDataset("average_grade", data?.data), handleSetPredictiveAverageGradeData, data?.total)
            }
        });
    }, []);

    return {
        /*gradesPerCourse,
        gradesPerCourseLoaded,*/
        averageCourseGrade,
        averageCourseGradeLoaded,
        predictedAverageGrade,
        predictedAverageGradeLoaded
    }

};

export default useGrading;
