import React from "react";
import "./Copilot.css";
import { Copilot as CopilotBox } from '@iblai/ibl-web-react-common';


const Copilot = props => {
    
    return (
<div className="w-layout-vflex copilot-container">
<div className="w-layout-vflex copilot-main-container">
<div className="div-block-354">
    <CopilotBox isWhiteThemed={false}/>
{/*<div className="html-embed-11 w-embed w-iframe"><iframe id="iframed-mentor" src="" style={{border: '0px white', height: '100%', width: '100%', visibility: 'visible'}}></iframe></div>*/}
</div>
</div>
<div className="w-layout-hflex resize-seperator-block ui-resizable-handle ui-resizable-w"></div>
</div>
)}

export default Copilot;