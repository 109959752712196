import React, { useReducer, useRef } from 'react';
import './InvitationsViewContent.css';
import {
  getCourseInvitations,
  getPlatformInvitations,
  getProgramInvitation,
} from '../../../hooks/utils/invitations';
import { getTableSizes } from '../../../hooks/helpers';
import { InvitationTableHeader } from '../../../components/Invitation/InvitationTableHeader/InvitationTableHeader';
import { InvitationsTable } from '../../../components/Invitation/InvitationsTable/InvitationsTable';
import { actionTypes, invitationReducer } from './InvitationsReducer';
import useInvitation from '../../../hooks/useInvitation';
import { usePlatformInvitations } from '../../../hooks/usePlatformInvitations';
import InviteUserPopUp from '../../../components/Invitation/InviteUserPopUp/InviteUserPopUp';
import RedeemInvitePopUp from '../../../components/Invitation/RedeemInvitePopUp/RedeemInvitePopUp';

const TABLE_SIZES = getTableSizes();

const initialState = {
  searchResults: [],
  resetFileInput: false,
  isInviteUserActive: false,
  source: '',
  isRedeemActive: false,
  userNameOrEmail: '',
  numberOfInvites: 0,
  platformKey: '',
  bulkCsvData: {
    invitation_data: [],
  },
  invitationsTab: {
    activeTab: 'Platform',
    activeInvitationMode: 'Single',
  },
  platformInvitationMetaData: {
    availableInvites: 0,
    pagination: {
      currentPage: 1,
      pageSize: TABLE_SIZES[0],
      totalItems: 0,
      totalPages: 0,
    },
  },
  activeTableInvitationType: 'Platform',
  selectedItemID: '',
  itemsLoaded: true,
};

const InvitationsViewContent = () => {
  const [state, dispatch] = useReducer(invitationReducer, initialState);
  const invitationFunctionMapping = {
    Platform: getPlatformInvitations,
    Course: getCourseInvitations,
    Program: getProgramInvitation,
  };
  const [
    platformInvitationData,
    platformInvitationsDataLoaded,
    setPlatformInvitationsData,
    setPlatformInvitationsDataLoaded,
  ] = useInvitation();

  usePlatformInvitations(
    state,
    dispatch,
    platformInvitationData,
    platformInvitationsDataLoaded
  );

  const reInitializeInvitationData = (data) => {
    setPlatformInvitationsData(data);
    setPlatformInvitationsDataLoaded(true);
  };

  const handlePageChange = (page, pageSize) => {
    dispatch({
      type: actionTypes.SET_PAGINATION_FIELD,
      field: 'currentPage',
      value: page,
    });

    invitationFunctionMapping[state.activeTableInvitationType](
      (data) => {
        reInitializeInvitationData(data);
      },
      pageSize
        ? pageSize
        : state.platformInvitationMetaData.pagination.pageSize,
      page
    );
  };

  return (
    <>
      <InviteUserPopUp
        state={state}
        dispatch={dispatch}
        handlePageChange={handlePageChange}
      />
      <RedeemInvitePopUp
        state={state}
        dispatch={dispatch}
        handlePageChange={handlePageChange}
      />
      <InvitationTableHeader dispatch={dispatch} />
      <InvitationsTable
        invitations={platformInvitationData}
        invitationsLoaded={platformInvitationsDataLoaded}
        reInitializeInvitationData={reInitializeInvitationData}
        state={state}
        dispatch={dispatch}
        onRedeemClick={(source, itemID) => {
          dispatch({ type: actionTypes.TOGGLE_REDEEM });
          dispatch({
            type: actionTypes.SET_FIELD,
            field: 'source',
            value: source,
          });
          dispatch({
            type: actionTypes.SET_FIELD,
            field: 'selectedItemID',
            value: itemID,
          });
        }}
        setPage={handlePageChange}
        setSize={(value) =>
          dispatch({
            type: actionTypes.SET_PAGINATION_FIELD,
            field: 'pageSize',
            value: value,
          })
        }
      />
    </>
  );
};

export default InvitationsViewContent;
