import { beginningDateRange, getTenant } from '../helpers';
import { api } from '@iblai/ibl-web-react-common';

export function getRegisteredUsersPerCourseAPI(callback) {
  api.iblaxdApiCallWrapper(
    (args) =>
      api.iblaxdaudience.audienceOrgsRegisteredUsersPerCourseRetrieve(...args),
    getTenant(),
    {}
  )((err, data, response) => (err, callback(data), response));
}

export function getRegisteredUsersOverTimeAPI(callback) {
  api.iblaxdApiCallWrapper(
    (args) =>
      api.iblaxdaudience.audienceOrgsRegisteredUsersOverTimeRetrieve(...args),
    getTenant(),
    beginningDateRange
  )((err, data, response) => (err, callback(data), response));
}
