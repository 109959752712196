import React from 'react';
import './InvitationDynamicInput.css';
import InvitationInput from './InvitationInput/InvitationInput';
import InvitationPlatformInput from '../InvitationPlatformInput/InvitationPlatformInput';

export const InvitationDynamicInput = ({
  state,
  dispatch,
  handleInputChange,
  children,
}) => {
  return (
    <div className="w-tab-content">
      <div
        data-w-tab="Platform"
        className="w-tab-pane w--tab-active"
        id="w-tabs-0-data-w-pane-0"
        role="tabpanel"
        aria-labelledby="w-tabs-0-data-w-tab-0"
      >
        <div className="div-block-228 less-pad">
          <div className="form-block-7 w-form">
            <form
              id="email-form-5"
              name="email-form-5"
              data-name="Email Form 5"
              method="get"
              aria-label="Email Form 5"
            >
              <div
                data-current="Individual"
                data-easing="ease"
                data-duration-in={300}
                data-duration-out={100}
                className="tabs-2 w-tabs"
                id="invite-mode"
              >
                {state.invitationsTab.activeInvitationMode !== 'Bulk' && (
                  <InvitationInput
                    state={state}
                    dispatch={dispatch}
                    handleInputChange={handleInputChange}
                  />
                )}

                {state.invitationsTab.activeInvitationMode === 'Bulk' && (
                  <InvitationPlatformInput state={state} dispatch={dispatch} />
                )}

                {children}
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};
