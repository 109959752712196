import { useTranslation } from 'react-i18next';

export default function CoursesController() {
    const { t } = useTranslation();

    return (
        <div>
            <h1>{t('Courses')}</h1>
            <p>{t('Browse our selection of courses and start learning today!')}</p>
            <button>{t('Enroll Now')}</button>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><path d="M3 11a9 9 0 0 1 9-9 9 9 0 0 1 9 9 9 9 0 0 1-9 9 9 9 0 0 1-9-9z"></path><path d="M16 16l5-5"></path><path d="M16 16l-4-4"></path></svg>
        </div>
    );
}