import React from 'react';
import { getTableSizes } from '../../hooks/helpers';

const TABLE_SIZES = getTableSizes();

const TableSizeDropdown = ({ setSize, setPage }) => {
  const handleDropdown = (e) => {
    setPage(1, e?.target?.value);
    setSize(e?.target?.value);
  };
  return (
      <form
          id="email-form-2"
          name="email-form-2"
          data-name="Email Form 2"
          method="get"
          data-wf-page-id="657af3ad1367da03b3b125d5"
          data-wf-element-id="4386085a-1eef-af03-c6b1-7a5a39f298eb"
      >
        <select
            id="field-2"
            name="field-2"
            data-name="Field 2"
            prop-events-names="onChange"
            prop-events-value-onchange="handleEntriesDropdownOnChange"
            onChange={handleDropdown}
            className="show w-select"
        >
          {TABLE_SIZES.map((size, index) => (
              <option key={index} value={size}>
                {size}
              </option>
          ))}
        </select>
      </form>
  );
};

export default TableSizeDropdown;
