import React, { useState, useCallback } from 'react';
import { InvitationModeTab } from '../InvitationModeTab/InvitationModeTab';
import { InvitationModeTabContent } from '../InvitationModeTabContent/InvitationModeTabContent';
import { InvitationDynamicInput } from '../InvitationDynamicInput/InvitationDynamicInput';
import { useDebounce } from '../../../hooks/useDebounce';
import { actionTypes } from '../../../views/content/InvitationsViewContent/InvitationsReducer';
import { handleInputChange } from '../../../hooks/helpers';

export const InvitationTabContent = ({ state, dispatch }) => {
  const [debouncedValue, setDebouncedValue] = useState('');

  const debouncedHandleInputChange = useCallback(
    (value) => {
      setDebouncedValue(value);
    },
    [setDebouncedValue]
  );

  useDebounce(
    () => {
      handleInputChange(debouncedValue, dispatch, state);
    },
    300,
    [debouncedValue]
  );

  const onChange = (value) => {
    debouncedHandleInputChange(value);
    dispatch({
      type: actionTypes.SET_FIELD,
      field: 'selectedItemID',
      value: value,
    });

    dispatch({
      type: actionTypes.SET_FIELD,
      field: 'itemsLoaded',
      value: false,
    });
  };

  return (
    <InvitationDynamicInput
      invitationsTab={state.invitationsTab}
      state={state}
      dispatch={dispatch}
      handleInputChange={onChange}
    >
      <InvitationModeTab state={state} dispatch={dispatch} />
      <InvitationModeTabContent state={state} dispatch={dispatch} />
    </InvitationDynamicInput>
  );
};
