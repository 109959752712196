import React from 'react';
import { actionTypes } from '../../../views/content/InvitationsViewContent/InvitationsReducer';
import { useTranslation } from 'react-i18next';

export const InvitationTab = ({ state, dispatch }) => {
  const { t } = useTranslation();
  return (
    <div className="tabs-menu w-tab-menu" role="tablist">
      <a
        data-w-tab="Platform"
        className={`invite-tab-link w-inline-block w-tab-link  ${state.invitationsTab.activeTab === 'Platform' ? 'w--current' : ''}`}
        id="w-tabs-0-data-w-tab-0"
        href="#"
        role="tab"
        onClick={() => {
          dispatch({
            type: actionTypes.SET_FIELD,
            field: 'invitationsTab',
            value: { ...state.invitationsTab, activeTab: 'Platform' },
          });
        }}
        aria-controls="w-tabs-0-data-w-pane-0"
        aria-selected="false"
        tabIndex={-1}
      >
        <div>{t('Platform')}</div>
      </a>
      <a
        data-w-tab="Program"
        className={`invite-tab-link w-inline-block w-tab-link  ${state.invitationsTab.activeTab === 'Program' ? 'w--current' : ''}`}
        id="w-tabs-0-data-w-tab-1"
        href="#"
        onClick={() => {
          dispatch({
            type: actionTypes.SET_FIELD,
            field: 'invitationsTab',
            value: { ...state.invitationsTab, activeTab: 'Program' },
          });
        }}
        role="tab"
        aria-controls="w-tabs-0-data-w-pane-1"
        aria-selected="false"
        tabIndex={-1}
      >
        <div>{t('Program')}</div>
      </a>
      <a
        data-w-tab="Course"
        className={`invite-tab-link w-inline-block w-tab-link  ${state.invitationsTab.activeTab === 'Course' ? 'w--current' : ''}`}
        id="w-tabs-0-data-w-tab-2"
        href="#"
        onClick={() => {
          dispatch({
            type: actionTypes.SET_FIELD,
            field: 'invitationsTab',
            value: { ...state.invitationsTab, activeTab: 'Course' },
          });
        }}
        role="tab"
        aria-controls="w-tabs-0-data-w-pane-2"
        aria-selected="true"
      >
        <div>{t('Course')}</div>
      </a>
    </div>
  );
};
