import { useEffect, useRef, useState } from 'react';
import { getUserCoursesMetaData } from '@iblai/ibl-web-react-common';
import { getAdminCoursesAPI } from './helpers';

export function useStudioAuthoringCourses(username) {
  const hasFetchedData = useRef(false);
  const [coursesData, setCoursesData] = useState([]);
  const [courseDataLoaded, setCourseDataLoaded] = useState(false);

  useEffect(() => {
    if (!hasFetchedData.current && username) {
      getAdminCoursesAPI(username)
        .then((courses) => {
          // Initialize courses data with empty metadata and search data
          const initialCoursesData = courses.map((course) => ({
            course: course,
            metadata: null,
            search: null,
          }));
          setCoursesData(initialCoursesData);
          setCourseDataLoaded(true);

          // Fetch metadata and search data for each course
          courses.forEach((course, index) => {
            getUserCoursesMetaData(course?.course_id).then((metadata) => {
              // Update the specific course's metadata
              setCoursesData((currentData) =>
                currentData?.map((item, idx) =>
                  idx === index ? { ...item, metadata: metadata } : item
                )
              );
            });
          });

          hasFetchedData.current = true;
        })
        .catch((error) => {
          // TODO : need to safely return empty course data to the API
          console.error('Error fetching user courses:', error);
        });
    }
  }, [username]);

  return [coursesData, courseDataLoaded];
}
