import Coursetable from '../Coursetable/Coursetable';
import React from 'react';
import './Coursecompletion.css';
import useCourseCompletion from '../../hooks/useCourseCompletion';
import DescriptiveLineChartGraph from '../DescriptiveLineChartGraph/DescriptiveLineChartGraph';
import { getCourseCompletionPerCourseAPI } from '../../hooks/utils/courseCompletion';
import { convertCompletionData } from '../../hooks/helpers';

const Coursecompletion = (props) => {
  const [
    contentCompletionOverTime,
    contentCompletionOverTimeLoaded,
    courseCompletionPerCourse,
    courseCompletionPerCourseLoaded,
  ] = useCourseCompletion();

  return (
    <div
      react-hook="useCourseCompletion"
      react-hook-return="courseContentCompletionOverTimeTotal,courseContentCompletionOverTimeLoaded,courseContentCompletionOverTimeChangeClass,courseContentCompletionOverTimeChangeSign,courseContentCompletionOverTimeNumericalChange,courseContentCompletionOverTimePercentageChange,courseContentCompletionOverTimeChangeSymbol,courseContentCompletionOverTimeChartData,predictedCourseContentCompletionTotal,predictedCourseContentCompletionLoaded,predictedCourseContentCompletionChangeClass,predictedCourseContentCompletionChangeSign,predictedCourseContentCompletionNumericalChange,predictedCourseContentCompletionPercentageChange,predictedCourseContentCompletionChangeSymbol,predictedCourseContentCompletionChartData,courseContentCompletionSelect,courseContentCompletionSearch,courseContentCompletionTableData,courseContentCompletionCurrentPage,courseContentCompletionItemsPerPage,courseContentCompletionTotalItems"
    >
      <div className="p-row r-r">
        <div className="div-block-204 l" style={{ width: '100%' }}>
          <DescriptiveLineChartGraph
            data={contentCompletionOverTime}
            dataLoaded={contentCompletionOverTimeLoaded}
            title={'Course Content Completion Over Time'}
            index={1}
          />
        </div>
      </div>
      <Coursetable
        useSizeDropdown={false}
        tableHeaders={['#', 'Course', 'Registered Users', 'Percentage']}
        endPoint={getCourseCompletionPerCourseAPI}
        title={'Course Content Completion'}
      ></Coursetable>
    </div>
  );
};

export default Coursecompletion;
