import { useTranslation } from 'react-i18next';

export default function PathwayController() {
  const { t } = useTranslation();

  return (
    <div>
      <h1>{t('Welcome to the app')}</h1>
      <p>{t('This is a demo pathway controller component for internationalization purposes.')}</p>
      <button>{t('Save Changes')}</button>
    </div>
  );
}