import React, {useEffect, useState} from "react";
import Descriptivepagetitle from "../../../components/Descriptivepagetitle/Descriptivepagetitle";
import Registeredusers from "../../../components/Registeredusers/Registeredusers";
import "./LicensingUserGroupsViewContent.css"
import {getTableSizes} from "../../../hooks/helpers";
import useLicensingContent from "../../../hooks/useLicensingContent";
import useLicensingGroup from "../../../hooks/useLicensingGroup";
import TableSizeDropdown from "../../../components/TableSizeDropdown/TableSizeDropdown";
import AddLicensingUserGroup from "../../../components/AddLicensingUserGroup/AddLicensingUserGroup";
import {IBLSpinLoader} from "@iblai/ibl-web-react-common";
import EmptyTableDataInfographic from "../../../components/EmptyTableDataInfographic/EmptyTableDataInfographic";
import LicensingUsersInGroup from "../../../components/LicensingUsersInGroup/LicensingUsersInGroup";

const LicensingUserGroupsViewContent = () => {
    const TABLE_SIZES = getTableSizes();
    const [search, setSearch] = useState('');
    const [size, setSize] = useState(TABLE_SIZES[0]);
    const [page, setPage] = useState(1);
    const [refresher, setRefresher] = useState(null);

    const {groups, groupLoading} = useLicensingGroup({search, page, size, refresher})
    const [focusedGroup, setFocusedGroup] = useState(null)

    const [addGroupModalIsFocused, setAddGroupModalIsFocused] = useState(false)

  return (
      <>
          <div className="p-row r-r">
              <div className="div-block-204 f-w">
                  <div className="div-block-183">
                      <div className="div-block-205">
                          <div className="div-block-206"></div>
                          <div className="text-block-113">Licensing: User Groups</div>
                      </div>
                      <div className="div-block-308">
                          <a href="#" className="link-block-22 hidden w-inline-block">
                              <div data-w-id="2e311dd0-e1c2-0ab6-f2a1-1e492e5123a6"
                                   className="div-block-207 first active follow-user alt-btn issue-popup">
                                  <div className="text-block-111">Assign License</div>
                              </div>
                          </a>
                          <a onClick={()=>setAddGroupModalIsFocused(true)} data-w-id="0f0fbe27-ba22-528c-b845-669b6f0cebd5" href="#"
                             className="link-block-22 w-inline-block">
                              <div data-w-id="0f0fbe27-ba22-528c-b845-669b6f0cebd6"
                                   className="div-block-207 first active follow-user create-cred-popup">
                                  <div className="text-block-111">Add Group</div>
                              </div>
                          </a>
                      </div>
                  </div>
                  <div react-component="CourseTable" className="table_cont-2">
                      <div className="div-block-217">
                          <div className="div-block-169">
                              <div>
                                  <div prop-data-value-tablename="Courses" prop-data-names="tableName"
                                       className="text-block-135">Groups<br/></div>
                              </div>
                          </div>
                          <div className="div-block-169">
                              <div className="div-block-171">
                                  <div className="sm">Show</div>
                                  <div className="form-block w-form">
                                      <TableSizeDropdown setSize={setSize} setPage={setPage} />
                                      <div className="w-form-done">
                                          <div>Thank you! Your submission has been received!</div>
                                      </div>
                                      <div className="w-form-fail">
                                          <div>Oops! Something went wrong while submitting the form.</div>
                                      </div>
                                  </div>
                                  <div className="sm">entries</div>
                              </div>
                              <div className="div-block-171">
                                  <div className="sm">Search:</div>
                                  <div className="form-block w-form">
                                      <form id="email-form-2" name="email-form-2" data-name="Email Form 2" method="get"
                                            data-wf-page-id="66e1c2afd7d305ab933f7a97"
                                            data-wf-element-id="4386085a-1eef-af03-c6b1-7a5a39f298f9">
                                          <input
                                              className="input w-input"
                                              data-name="Field 3"
                                              id="field-3"
                                              maxLength={256}
                                              name="field-3"
                                              placeholder=""
                                              required=""
                                              type="text"
                                              value={search}
                                              onChange={(e) => {
                                                  setSearch(e?.target?.value);
                                              }} />
                                      </form>
                                      <div className="w-form-done">
                                          <div>Thank you! Your submission has been received!</div>
                                      </div>
                                      <div className="w-form-fail">
                                          <div>Oops! Something went wrong while submitting the form.</div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                          <div react-component="table" className="table">
                              <div className="th">
                                  <div className="tc _10">
                                      <div className="sm">#</div>
                                  </div>
                                  <div className="tc _50">
                                      <div className="w_sort">
                                          <div className="text-block-134 fa_icon sort"></div>
                                          <div className="sm">Name</div>
                                      </div>
                                  </div>
                                  <div className="tc _20">
                                      <div className="w_sort">
                                          <div className="text-block-134 fa_icon sort"></div>
                                          <div className="sm">Total Users</div>
                                      </div>
                                  </div>
                                  <div className="tc _20">
                                      <div className="w_sort hidden">
                                          <div className="text-block-134 fa_icon sort"></div>
                                          <div className="sm">Usage</div>
                                      </div>
                                  </div>
                              </div>
                              {
                                  groupLoading ?
                                      <IBLSpinLoader containerHeight={"200px"} size={20} color={"white"}/> :
                                      groups.length > 0 ?
                                          groups.map((group, index)=> (
                                              <div key={`group-nbr-${index}`}  className="tr">
                                                  <div className="tc tcell _10">
                                                      <div className="m">{index+1}</div>
                                                  </div>
                                                  <div data-w-id="4386085a-1eef-af03-c6b1-7a5a39f2991c"
                                                       className="tc tcell _50">
                                                      <a onClick={()=>setFocusedGroup(group)} href="#" className="tl-2 np"><span
                                                          className="nb">{group?.name}</span></a>
                                                      <div className="w-layout-hflex flex-block-16"><img
                                                          src="/images/eye-svgrepo-com.svg" loading="lazy" alt=""
                                                          className="image-145"/>
                                                      </div>
                                                  </div>
                                                  <div className="tc tcell _20">
                                                      <div className="m">-</div>
                                                  </div>
                                                  <div className="tc tcell w_a _20">
                                                      <div className="div-block-2 options options_trigger no-margin">
                                                          <div className="w-embed"><label className="switch"><input
                                                              type="checkbox"
                                                              className="platform-switch"
                                                              data-username="hajar585"
                                                              id="toggle"
                                                              checked={group?.active}/><span
                                                              className="slider round"></span></label></div>
                                                      </div>
                                                  </div>
                                              </div>
                                          ))
                                          :
                                          <EmptyTableDataInfographic/>
                              }
                          </div>
                      </div>
                  </div>
                  {
                      focusedGroup && <LicensingUsersInGroup group={focusedGroup} setFocused={setFocusedGroup} setAddUsersFocused={setAddGroupModalIsFocused} setRefresher={setRefresher} refresher={refresher} />
                  }
                  {
                      addGroupModalIsFocused && <AddLicensingUserGroup setFocused={setAddGroupModalIsFocused} group={focusedGroup} refresher={refresher} setRefresher={setRefresher}  />
                  }
              </div>
          </div>
      </>
  );
};

export default LicensingUserGroupsViewContent;
