import React, { useEffect, useState } from 'react';
import './Verticalgraphcard.css';
import Verticalgraphcanvas from '../VerticalGraphCanvas/Verticalgraphcanvas';
import { IBLSpinLoader } from '@iblai/ibl-web-react-common';
import {
  DESCRIPTIVE_TIMEFRAMES_OPTIONS,
  PREDICTIVE_TIMEFRAMES_OPTIONS,
} from '../../hooks/helpers';
// TODO : delete all console.log statements
const Verticalgraphcard = ({
  data,
  dataLoaded,
  title,
  label,
  fullWidth,
  index,
  isPredictive = false,
}) => {
  const [timeFrames, setTimeFrames] = useState([]);
  const [first_five_elements, setfirst_five_elements] = useState([]);
  const [selectedTimeFrame, setSelectedTimeFrame] = useState(
    !isPredictive ? 'change_last_seven_days' : 'nm'
  );
  useEffect(() => {
    if (dataLoaded && data.data.length > 0) {
      setfirst_five_elements(data.data.slice(0, Math.min(5, data.data.length)));
    }
  }, [dataLoaded]);

  useEffect(() => {
    if (!isPredictive) {
      setTimeFrames(DESCRIPTIVE_TIMEFRAMES_OPTIONS);
    } else {
      setTimeFrames(PREDICTIVE_TIMEFRAMES_OPTIONS);
    }
  }, []);
  return (
    <div
      react-component="VerticalGraphCard"
      className="div-block-204 l"
      style={{ width: fullWidth ? 'inherit' : '100%' }}
    >
      <div
        react-component="ActiveCoursesGraph"
        className="table_cont mgt0 n-ov"
      >
        <div className="div-block-202">
          <div className="div-block-235">
            <div
              prop-data-value-cardtitle="MOST ACTIVE COURSES"
              prop-data-names="cardTitle"
              className="text-block-109 o-t"
            >
              {title}
              <br />
            </div>

            {/*<div className="form-block-2 w-form">
              <form
                id="email-form-2"
                name="email-form-2"
                data-name="Email Form 2"
                method="get"
                data-wf-page-id="657af3ad1367da03b3b12528"
                data-wf-element-id="4772e990-a981-577d-88e1-a068f2afc5ba"
              >
                <select
                  id="field-5"
                  name="field-5"
                  data-name="Field 5"
                  value={selectedTimeFrame}
                  onChange={(e) => setSelectedTimeFrame(e.target.value)}
                  className="select-field-6 w-select"
                >
                  {timeFrames?.map((timeframe, index) => (
                    <option key={index} value={timeframe?.value}>
                      {timeframe?.label}
                    </option>
                  ))}
                </select>
              </form>
               Additional form handling components
            </div>*/}
          </div>
          <div className="div-block-237">
            <div className="text-block-110">{data?.total}</div>
          </div>

          <div className="chart-area pd0">
            <div className="html-embed r w-embed w-script">
              <style
                dangerouslySetInnerHTML={{
                  __html:
                    '\n .af-view #MeSeStatusCanvasIBL{\n \tmax-width:93.5% !important;\n  margin-left:auto;\n \tmargin-right:auto;\n    height:150px !important;\n }\n',
                }}
              />
              {dataLoaded ? (
                <Verticalgraphcanvas
                  data={first_five_elements}
                  label={label}
                  index={index}
                />
              ) : (
                <IBLSpinLoader
                  size={20}
                  color={'#FFF'}
                  containerHeight={'150px'}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Verticalgraphcard;
