import React, { useEffect, useState } from 'react';
import { defaultSPALogoData, getTenant } from '../../hooks/helpers';

const Logo = ({}) => {
  const [logoURL, setLogoURL] = useState(
    `${process.env.REACT_APP_IBL_AXD_URL}/api/core/orgs/${getTenant()}/dark-mode-logo/`
  );
  const [errorCount, setErrorCount] = useState(0);

  const handleImageError = (e) => {
    setErrorCount((prevCount) => prevCount + 1);
    if (errorCount < 3) {
      e.target.src = defaultSPALogoData.logo;
    } else {
      console.error('Image failed to load more than three times.');
    }
  };

  return (
    <img
      alt=""
      className="image"
      loading="lazy"
      onError={handleImageError}
      sizes="(max-width: 991px) 100vw, 202.1640625px"
      src={logoURL}
    />
  );
};

export default Logo;
