import React, { useRef, useState, useEffect } from 'react';
import { actionTypes } from '../../../views/content/InvitationsViewContent/InvitationsReducer';
import { extractInvitationCsvData } from '../../../hooks/utils/invitations';
import { useTranslation } from 'react-i18next';

export const InvitationModeTabContent = ({ state, dispatch }) => {
  const fileInputRef = useRef(null);
  const [fileName, setFileName] = useState('');
  const { t } = useTranslation();

  const handleCSVClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];

    if (selectedFile) {
      setFileName(selectedFile.name);
    }
    extractInvitationCsvData(selectedFile, state, dispatch);
  };

  const resetFileInput = () => {
    setFileName('');
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  useEffect(() => {
    if (state.resetFileInput) {
      resetFileInput();
      dispatch({ type: actionTypes.CLEAR_RESET_FILE_INPUT_FLAG });
    }
  }, [state.resetFileInput, dispatch]);

  return (
    <div className="tabs-content w-tab-content">
      <div
        data-w-tab="Individual"
        className={`w-tab-pane  ${state.invitationsTab.activeInvitationMode === 'Single' ? 'w--tab-active' : ''}`}
        id="w-tabs-1-data-w-pane-0"
        role="tabpanel"
        aria-labelledby="w-tabs-1-data-w-tab-0"
        style={{
          opacity: 1,
          transition: 'opacity 300ms ease 0s',
        }}
      >
        <label htmlFor="name-3" className="text-block-139-bd-f-soc">
          {t('User*')}
        </label>
        <input
          className="text-field-3 w-input"
          value={state.userNameOrEmail}
          onChange={(e) => {
            dispatch({
              type: actionTypes.SET_FIELD,
              field: 'userNameOrEmail',
              value: e.target.value,
            });
          }}
          name="name-2"
          data-name="Name 2"
          placeholder="Enter a username or an email"
          type="text"
          id="name-2"
        />
      </div>
      <div
        data-w-tab="Bulk"
        className={`w-tab-pane  ${state.invitationsTab.activeInvitationMode === 'Bulk' ? 'w--tab-active' : ''}`}
        id="w-tabs-1-data-w-pane-1"
        role="tabpanel"
        aria-labelledby="w-tabs-1-data-w-tab-1"
      >
        <label className="text-block-139-bd-f-soc" htmlFor="name-3">
          {t('Import CSV')}
        </label>
        <div className="div-block-307">
          <div
            className="div-block-207 first active follow-user"
            onClick={handleCSVClick}
          >
            <div className="text-block-111 red-text cf">{t('Choose file')}</div>
          </div>
          <input
            type="file"
            ref={fileInputRef}
            onChange={handleFileChange}
            style={{ display: 'none' }}
            accept=".csv" // Restrict to CSV files
          />
          <div className="text-block-167">{fileName || 'No file chosen'}</div>
        </div>
      </div>
      <div
        data-w-tab="MultiSeat"
        className={`w-tab-pane  ${state.invitationsTab.activeInvitationMode === 'Seats' ? 'w--tab-active' : ''}`}
        id="w-tabs-1-data-w-pane-2"
        role="tabpanel"
        aria-labelledby="w-tabs-1-data-w-tab-2"
      >
        <label htmlFor="name-2" className="text-block-139-bd-f-soc">
          {t('Number of Invites*')}
        </label>
        <input
          className="text-field-3 w-input"
          name="name-2"
          value={state.numberOfInvites}
          onChange={(e) => {
            dispatch({
              type: actionTypes.SET_FIELD,
              field: 'numberOfInvites',
              value: e.target.value,
            });
          }}
          data-name="Name 2"
          placeholder={t('Enter number of invites')}
          type="number"
          id="name-2"
          min="1"
        />
      </div>
    </div>
  );
};
