import React, { useContext, useEffect, useRef, useState } from 'react';

import './StudioAuthoringSettingsViewContent.css';
import { getTenant } from '../../../hooks/helpers';
import { setStudioLogo, setStudioDarkLogo } from '../../../hooks/utils/studio';
import { IBLSpinLoader } from '@iblai/ibl-web-react-common';

const StudioAuthoringSettingsViewContent = () => {
  const inputLightImgUploadRef = useRef(null);
  const inputDarkImgUploadRef = useRef(null);

  const handleLightUploaderTrigger = () => {
    inputLightImgUploadRef.current.click();
  };

  const handleDarkUploaderTrigger = () => {
    inputDarkImgUploadRef.current.click();
  };

  const [lightLogoURL, setLightLogoURL] = useState('');
  const [darkLogoURL, setDarkLogoURL] = useState('');
  const [lightLogoURLUpdating, setLightLogoURLUpdating] = useState(false);
  const [darkLogoURLUpdating, setDarkLogoURLUpdating] = useState(false);

  const handleLightImgUploadChange = async (e) => {
    if (!e) {
      return;
    }
    setLightLogoURLUpdating(true);
    const file = e?.target?.files[0];
    inputLightImgUploadRef.current.value = '';
    const formData = new FormData();
    formData.append('file', file, file.name);
    setStudioLogo(formData, (response) => {
      setLightLogoURL(response.url);
      setLightLogoURLUpdating(false);
    });
  };

  const handleDarkImgUploadChange = async (e) => {
    if (!e) {
      return;
    }
    setDarkLogoURLUpdating(true);
    const file = e?.target?.files[0];
    inputDarkImgUploadRef.current.value = '';
    const formData = new FormData();
    formData.append('file', file, file.name);
    setStudioDarkLogo(formData, (response) => {
      setDarkLogoURL(response.url);
      setDarkLogoURLUpdating(false);
    });
  };

  const handleLoadSettingsData = () => {
    setLightLogoURL(
      `${process.env.REACT_APP_IBL_AXD_URL}/api/core/orgs/${getTenant()}/logo/`
    );
    setDarkLogoURL(
      `${process.env.REACT_APP_IBL_AXD_URL}/api/core/orgs/${getTenant()}/dark-mode-logo/`
    );
  };

  useEffect(() => {
    handleLoadSettingsData();
  }, []);

  return (
    <>
      <div className="div-block-183">
        <div className="div-block-205">
          <div className="div-block-206"></div>
          <div className="text-block-113">STUDIO | SETTINGS</div>
        </div>
      </div>
      <div className="div-block-326 l map">
        <div
          react-hook="useStudioSettings"
          react-component="StudioSettingsTable"
          className="table_cont-2"
        >
          <div className="div-block-325 w_bg nbg">
            <div className="text-block-172">Platform Logos</div>
            <div className="table">
              <div className="tr">
                <div className="tc tcell">
                  <div className="m-2">Light Logo</div>
                </div>
                <div className="tc tcell _35">
                  {lightLogoURLUpdating ? (
                    <IBLSpinLoader color={'#FFF'} size={20} />
                  ) : (
                    <div className="div-block-271">
                      <img
                        className="image-94"
                        src={lightLogoURL}
                        alt=""
                        loading="lazy"
                      />
                    </div>
                  )}
                </div>
                <div className="tc tcell _32-5">
                  <div
                    onClick={() => handleLightUploaderTrigger()}
                    className="div-block-327 first active edit edit_logo"
                  >
                    <div className="text-block-173">Edit</div>
                  </div>
                  <div className="html-embed-10 w-embed">
                    <input
                      onChange={handleLightImgUploadChange}
                      ref={inputLightImgUploadRef}
                      type="file"
                      prop-events-names="onChange"
                      prop-events-value-onchange="handleLightLogoOnChange"
                      className="file-upload light-logo"
                      accept=".jpg, .jpeg, .png, .gif, .svg"
                      style={{ display: 'none' }}
                      autoComplete="off"
                    />
                  </div>
                </div>
              </div>
              <div className="tr">
                <div className="tc tcell">
                  <div className="m-2">Dark Logo</div>
                </div>
                <div className="tc tcell _35">
                  {darkLogoURLUpdating ? (
                    <IBLSpinLoader color={'#FFF'} size={20} />
                  ) : (
                    <div className="div-block-271">
                      <img
                        className="image-94"
                        src={darkLogoURL}
                        alt=""
                        loading="lazy"
                      />
                    </div>
                  )}
                </div>
                <div className="tc tcell _32-5">
                  <div
                    onClick={() => handleDarkUploaderTrigger()}
                    className="div-block-327 first active edit edit_logo"
                  >
                    <div className="text-block-173">Edit</div>
                  </div>
                  <div className="html-embed-10 w-embed">
                    <input
                      onChange={handleDarkImgUploadChange}
                      ref={inputDarkImgUploadRef}
                      type="file"
                      prop-events-names="onChange"
                      prop-events-value-onchange="handleDarkLogoOnChange"
                      className="file-upload dark-logo"
                      accept=".jpg, .jpeg, .png, .gif, .svg"
                      style={{ display: 'none' }}
                      autoComplete="off"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default StudioAuthoringSettingsViewContent;
