import React from "react";
import "./Descriptivepagetitle.css";
import i18n from 'i18next';

const Descriptivepagetitle = ({title=i18n.t("OVERVIEW AI"), isPredictive=false}) => {
  return (
    <div className="div-block-205">
      <div className={`div-block-206 ${isPredictive ? "pr" : ""}`}></div>
      <div className="text-block-113" prop-pagetitle="DESCRIPTIVE DATA">
          {title}
      </div>
    </div>
  );
};

export default Descriptivepagetitle;
