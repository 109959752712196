import { useTranslation } from 'react-i18next';

export default function GradingController() {
  const { t } = useTranslation();

  return (
    <div>
      <h1>{t('Grade Controller')}</h1>
      <p>{t('Select the grade for the student')}:</p>
      <button>{t('A')}</button>
      <button>{t('B')}</button>
      <button>{t('C')}</button>
    </div>
  );
}