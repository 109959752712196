import React, {useEffect, useState} from 'react';
import {getCredentialsIssued, getCredentialsPerCourse} from "./utils/credentials";

const UseCredentials = () => {

    const [credentialsOverTime, setCredentialsOverTime] = useState([])
    const [credentialsOverTimeLoaded, setCredentialsOverTimeLoaded] = useState(false)
    /*const [credentialsPerCourse, setCredentialsPerCourse] = useState([])
    const [credentialsPerCourseLoaded, setCredentialsPerCourseLoaded] = useState(false)*/

    const handleSetCredentialsOverTime = (data) => {
        setCredentialsOverTime(data)
        setCredentialsOverTimeLoaded(true)
    }

    /*const handleSetCredentialsPerCourse = (data) => {
        const updatedData = {
            ...data,
            ...(!data?.data && data?.results ? {
                data:data?.results
            }  : {})
        }
        setCredentialsPerCourse(updatedData)
        setCredentialsPerCourseLoaded(true)

    }*/

    useEffect(() => {
        getCredentialsIssued(handleSetCredentialsOverTime)
       // getCredentialsPerCourse(handleSetCredentialsPerCourse)
    }, []);


    return {
        credentialsOverTime,
        credentialsOverTimeLoaded,
        /*credentialsPerCourse,
        credentialsPerCourseLoaded*/
    }
};

export default UseCredentials;