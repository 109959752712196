import { useEffect, useState } from 'react';
import {
    getContentCompletionOverTimeAPI,
    getCourseCompletionPerCourseAPI,
} from './utils/courseCompletion';  // Replace with the correct path

const useCourseCompletion = () => {
    const [contentCompletionOverTime, setContentCompletionOverTime] = useState([]);
    const [contentCompletionOverTimeLoaded, setContentCompletionOverTimeLoaded] = useState(false);

    /*const [courseCompletionPerCourse, setCourseCompletionPerCourse] = useState([]);
    const [courseCompletionPerCourseLoaded, setCourseCompletionPerCourseLoaded] = useState(false);*/

    useEffect(() => {
        getContentCompletionOverTimeAPI((data) => {
            setContentCompletionOverTime(data);
            setContentCompletionOverTimeLoaded(true);
        });

        // Assuming you have a variable for the current page in your component
        /*const currentPage = 1;  // Replace with your logic to get the current page

        getCourseCompletionPerCourseAPI(currentPage, (data) => {
            setCourseCompletionPerCourse(data);
            setCourseCompletionPerCourseLoaded(true);
        });*/
    }, []);

    return [
        contentCompletionOverTime,
        contentCompletionOverTimeLoaded,
        /*courseCompletionPerCourse,
        courseCompletionPerCourseLoaded,*/
    ]


};

export default useCourseCompletion;
