import { useEffect, useRef, useState } from 'react';
import { getAdminProgramsAPI } from './helpers';

export const useStudioAuthoringPrograms = (username) => {
  const hasFetchedData = useRef(false);
  const [profilePrograms, setProfilePrograms] = useState([]);
  const [profileProgramsLoaded, setProfileProgramsLoaded] = useState(false);

  useEffect(() => {
    if (!hasFetchedData.current) {
      getAdminProgramsAPI(
        username,
        (programs) => {
          setProfilePrograms(programs);
          setProfileProgramsLoaded(true);
        },
        (programDetail) => {
          // Update the specific program's detail asynchronously
          setProfilePrograms((currentPrograms) => {
            return currentPrograms.map((program) => {
              if (program.program_id === programDetail.program_id) {
                return { ...program, ...programDetail };
              }
              return program;
            });
          });
        }
      );
      hasFetchedData.current = true;
    }
  }, []);

  return [profilePrograms, profileProgramsLoaded];
};
