import React from 'react';
import { useTranslation } from 'react-i18next';

export default function AboutController() {
    const { t } = useTranslation();

    return (
        <div>
            <h1>{t('About Us')}</h1>
            <p>{t('This is a placeholder text for the about page content.')}</p>
            <button>{t('Learn More')}</button>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12 3L22.39 9.5L18.5 20.39L12 16.5L5.5 20.39L1.61 9.5L12 3Z" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
        </div>
    );
}