import { api } from '@iblai/ibl-web-react-common';
import { beginningDateRange, getTenant } from '../helpers';

export function getEnrollmentsOverTimeAPI(callback) {
  api.iblaxdApiCallWrapper(
    (args) =>
      api.iblaxdaudience.audienceOrgsEnrollmentsOverTimeRetrieve(...args),
    getTenant(),
    beginningDateRange
  )((err, data, response) => (err, callback(data), response));
}

export function getEnrollmentsPerCourseAPI(additionalData, callback) {
  api.iblaxdApiCallWrapper(
    (args) =>
      api.iblaxdaudience.audienceOrgsRegisteredUsersPerCourseRetrieve(...args),
    getTenant(),
    { ...additionalData }
  )((err, data, response) => (err, callback(data), response));
}
