import { useTranslation } from 'react-i18next';

export default function SettingsController() {
    const { t } = useTranslation();

    return (
        <div>
            <h1>{t('Settings')}</h1>
            <p>{t('Welcome to the settings page!')}</p>
            <button>{t('Save Changes')}</button>
        </div>
    );
}