import { useEffect } from 'react';
import { actionTypes } from '../views/content/InvitationsViewContent/InvitationsReducer';

export const usePlatformInvitations = (
  state,
  dispatch,
  platformInvitationData,
  platformInvitationsDataLoaded
) => {
  useEffect(() => {
    if (platformInvitationsDataLoaded) {
      getAvailableInvitations();
      handleInvitationPagination();
    }
  }, [platformInvitationsDataLoaded, platformInvitationData]);

  const getAvailableInvitations = () => {
    const availableInvites = platformInvitationData?.results?.filter(
      (invite) => !invite.email && !invite.username
    ).length;
    dispatch({
      type: actionTypes.SET_PLATFORM_AVAILABLE_INVITES,
      value: availableInvites,
    });
  };

  const handleInvitationPagination = () => {
    const totalItems = platformInvitationData.count;
    const pageSize = state.platformInvitationMetaData.pagination.pageSize;
    const totalPages = Math.ceil(totalItems / pageSize);

    dispatch({
      type: actionTypes.SET_PLATFORM_INVITATION_META_DATA,
      value: {
        pagination: {
          ...state.platformInvitationMetaData.pagination,
          totalItems,
          totalPages,
        },
      },
    });
  };
};
