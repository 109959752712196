import { api } from '@iblai/ibl-web-react-common';
import { getTenant } from '../helpers';

export function getPredictiveData(
  userID,
  dataset,
  callback,
  customData = null
) {
  api.iblaxdApiCallWrapper(
    (args) =>
      api.iblaxdaimentorapi.aiMentorOrgsUsersPredictiveAnalyticsCreate(...args),
    getTenant(),
    userID,
    dataset
  )(
    (err, data, response) => (
      err, data, callback(response?.body?.predictions, customData)
    )
  );
}

export function getFormattedPredictiveDataset(
  variableName,
  data,
  dataPoints = 31
) {
  return {
    prompt: {
      data_variables: [
        {
          variable_name: variableName,
          data_set: data,
          number_of_data_points: dataPoints,
        },
      ],
    },
  };
}
