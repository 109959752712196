import { api } from '@iblai/ibl-web-react-common';
import { getTenant } from '../helpers';

export function getPerLearnerUsers(inputVal, size = 10, callback, page = 1) {
  api.iblaxdApiCallWrapper(
    (args) => api.iblaxdperlearner.perlearnerOrgsUsersRetrieve(...args),
    getTenant(),
    { search: inputVal, length: size, page }
  )((err, data, response) => (err, callback(data), response));
}
