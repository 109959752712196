import React, {useState} from 'react';
import Async, { useAsync } from 'react-select/async';
import useLicensingUsers from "../../hooks/useLicensingUsers";
import {getTableSizes} from "../../hooks/helpers";
import {IBLSpinLoader} from "@iblai/ibl-web-react-common";
import './AddLicensingUserGroup.css'

const AddLicensingUserGroup = ({setFocused, group=null, setRefresher=()=>{}}) => {

    const TABLE_SIZES = getTableSizes();
    const [search, setSearch] = useState('');
    const [size, setSize] = useState(TABLE_SIZES[0]);
    const [page, setPage] = useState(1);
    const [fields, setFields] = useState({
        groupName:group?.name || "",
        users:[]
    })
    const [notification, setNotification] = useState({
        success:null,
        msg:null
    })
    const handleSetNotification = (success, msg) => {
        setNotification({
            msg: msg,
            success
        })
        setTimeout(()=>{
            setNotification({})
        }, 2500)
    }
    const {getUsersForSelectDropdown, createUserGroupsAndAssignUsers, handleAssignUserToGroup} = useLicensingUsers({search, size, page})
    const [submitting, setSubmitting] = useState(false)

    const promiseOptions = (inputValue) => {
        return new Promise((resolve)=>{
            getUsersForSelectDropdown(inputValue, (data)=>{
                const result = data?.results
                if(Array.isArray(result) && result.length > 0){
                    resolve(result.filter((pre)=>pre.name!=="").map((user)=>{
                        return {
                            value:user.user_id, label:user.name
                        }
                    }))
                }else{
                    resolve([])
                }
            })
        })
    }

    const handleMultiSelectChange = (choice) => {
        setFields({
            ...fields,
            users:[...choice]
        })
    }

    const handleSuccess = () => {
        setSubmitting(false)
        handleSetNotification(true, !!group ? "Users added!" : "Group created successfully!")
        setTimeout(()=>{
            setRefresher(Date.now())
            setFocused(false)
        }, 2000)
    }

    const handleError = (msg) => {
        setSubmitting(false)
        handleSetNotification(true,  "An error occurred. Please try again!")
    }

    const handleSubmit = (e) => {
        setSubmitting(true)
        if(!!group){
            handleAssignUserToGroup({groupID:group?.group_id, users:fields?.users}, handleSuccess, handleError)
        }else{
            createUserGroupsAndAssignUsers(fields, handleSuccess, handleError)
        }
    }

    return (
        <div style={{display:"flex"}} className="popup_div-nm_popup enroll-programs-modal active">
            <div className="popup_cont-sk">
                <div className="hf_popup program-enroll-popup active">
                    <div className="div-block-147 new_msg">
                        <div className="text-block-135 mg-0">{!!group ? `Add users to ${group?.name}` : "Add a group"}</div>
                        {
                            notification?.msg && (
                                <div className={`license-notification-block ${!notification?.success ? "error" : ""}`}>
                                    {notification?.msg}
                                </div>
                            )
                        }
                    </div>
                    <div className="div-block-228">
                        <div className="form-block-7 w-form">
                            <form id="email-form-5" name="email-form-5" data-name="Email Form 5" method="get"
                                  data-wf-page-id="66e1c2afd7d305ab933f7a97"
                                  data-wf-element-id="5f64487e-caf3-b6dd-b426-6888986a7eb7"><label htmlFor="name-2"
                                                                                                   className="text-block-139-bd-f-soc">Group
                                Name *</label>
                                <input value={ fields?.groupName} onChange={!group ? e=>setFields({
                                    ...fields,
                                    groupName: e?.target?.value
                                }) : ()=>{}} disabled={!!group} readOnly={!!group}  className="text-field-3 w-input license-name-input" maxLength="256" name="name-2"
                                                     data-name="Name 2" placeholder="Group name" type="text"
                                                     id="name-2"/>
                                <label htmlFor="name-2"
                                                                         className="text-block-139-bd-f-soc">Group
                                Users *</label>
                                <Async cacheOptions isMulti classNamePrefix={"react-select"} defaultOptions loadOptions={promiseOptions} options={fields?.users} onChange={handleMultiSelectChange} />
                                {/*<div className="w-layout-hflex flex-block-14"><img src="images/search-1.svg"
                                                                                   loading="lazy" alt=""
                                                                                   className="image-143"/><input
                                    className="text-field-10 hidden w-input" maxLength="256" name="field-6"
                                    data-name="Field 6" placeholder="Select users" type="text" id="field-6"/>
                                    <div className="w-layout-hflex grid-5">
                                        <div id="w-node-_26168a34-f341-a4b1-66ef-30f90ba47bd7-933f7a97"
                                             className="w-layout-hflex flex-block-15">
                                            <div>Sam S</div>
                                            <img src="images/x-3.svg" loading="lazy" alt="" className="image-144"/>
                                        </div>
                                        <div id="w-node-_26168a34-f341-a4b1-66ef-30f90ba47bdb-933f7a97"
                                             className="w-layout-hflex flex-block-15">
                                            <div>Tom P</div>
                                            <img src="images/x-3.svg" loading="lazy" alt="" className="image-144"/>
                                        </div>
                                        <div id="w-node-_26168a34-f341-a4b1-66ef-30f90ba47bdf-933f7a97"
                                             className="w-layout-hflex flex-block-15">
                                            <div>Danny K</div>
                                            <img src="images/x-3.svg" loading="lazy" alt="" className="image-144"/>
                                        </div>
                                        <div id="w-node-_26168a34-f341-a4b1-66ef-30f90ba47be3-933f7a97"
                                             className="w-layout-hflex flex-block-15">
                                            <div>Jon L</div>
                                            <img src="images/x-3.svg" loading="lazy" alt="" className="image-144"/>
                                        </div>
                                        <div id="w-node-_26168a34-f341-a4b1-66ef-30f90ba47be7-933f7a97"
                                             className="w-layout-hflex flex-block-15 no-closer">
                                            <div>+2</div>
                                        </div>
                                    </div>
                                </div>*/}
                            </form>
                            <div className="w-form-done">
                                <div>Thank you! Your submission has been received!</div>
                            </div>
                            <div className="w-form-fail">
                                <div>Oops! Something went wrong while submitting the form.</div>
                            </div>
                        </div>
                    </div>
                    <div className="div-block-147 new_msg">
                        <div className="div-block-264 f-w">
                            <div onClick={()=>setFocused(false)} data-w-id="5f64487e-caf3-b6dd-b426-6888986a7ec6"
                                 className="div-block-207 first active follow-user alt-btn cancel-program-enroll">
                                <div className="text-block-111 red-text cf">Cancel</div>
                            </div>
                            <div onClick={!submitting ? handleSubmit : ()=>{}} data-w-id="5f64487e-caf3-b6dd-b426-6888986a7ec9"
                                 className="div-block-207 first active follow-user redeem-btn mg-l16 program-enroll">
                                <div className="text-block-111 red-text cf">Add </div> {submitting && <span style={{marginLeft:"5px"}}> <IBLSpinLoader size={15} color={"#FFF"} /> </span>}
                            </div>
                        </div>
                    </div>
                    <div className="w-layout-vflex enroll-notification-block"><img src="images/check_2.png"
                                                                                   loading="lazy" sizes="100vw"
                                                                                   srcSet="images/check_2-p-500.png 500w, images/check_2.png 512w"
                                                                                   alt="" className="image-141"/>
                        <h4 className="heading-4">Program enrolled successfully</h4>
                        <div data-w-id="5f64487e-caf3-b6dd-b426-6888986a7ed0"
                             className="div-block-207 first active follow-user alt-btn cancel-program-enroll">
                            <div className="text-block-111 red-text cf">Close</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AddLicensingUserGroup;