import React from 'react';
import { actionTypes } from '../../../views/content/InvitationsViewContent/InvitationsReducer';
import { useTranslation } from 'react-i18next';

export const InvitationTableHeader = ({ dispatch }) => {
  const { t } = useTranslation();
  return (
    <div className="div-block-183">
      <div className="div-block-205">
        <div className="div-block-206"></div>
        <div className="text-block-113">
          {t('INVITES')}
          <br />
        </div>
      </div>
      <div className="div-block-308">
        <div
          className="div-block-207 first active follow-user invite-btn"
          onClick={() => {
            dispatch({ type: actionTypes.TOGGLE_INVITE_USER });
          }}
        >
          <div className="text-block-111">{t('Invite')}</div>
        </div>
      </div>
    </div>
  );
};
