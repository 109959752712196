import { useTranslation } from 'react-i18next';

export default function EnrollmentsController() {
  const { t } = useTranslation();

  return (
    <div>
      <h1>{t('Enrollments')}</h1>
      <p>{t('Welcome to the enrollments page!')}</p>
      <button>{t('Enroll Now')}</button>
    </div>
  );
}