import { useTranslation } from 'react-i18next';

export default function ActiveUsersController() {
    const { t } = useTranslation();

    return (
        <div>
            <h1>{t('Active Users')}</h1>
            <p>{t('There are currently {num} active users.', { num: 10 })}</p>
            <button>{t('Load More')}</button>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                <path d="M3 6h18v2H3zM3 10h18v2H3zM3 14h18v2H3zM3 18h18v2H3z"/>
            </svg>
        </div>
    );
}