import React from 'react';
import { useTranslation } from 'react-i18next';

export default function CourseCompletionController() {
    const { t } = useTranslation();

    return (
        <div>
            <h1>{t('Course Completion')}</h1>
            <p>{t('Congratulations on completing the course!')}</p>
            <button>{t('Finish')}</button>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                <path d="M0 0h24v24H0z" fill="none"/>
                <path d="M12 3c-4.41 0-8 3.59-8 8s3.59 8 8 8 8-3.59 8-8-3.59-8-8-8zm0 14c-3.31 0-6-2.69-6-6s2.69-6 6-6 6 2.69 6 6-2.69 6-6 6zm-2-9h4v4h-4z"/>
            </svg>
        </div>
    );
}