import Table from "../Table/Table";
import Gradepercourserow from "../Gradepercourserow/Gradepercourserow";
import Coursetable from "../Coursetable/Coursetable";
import Gradegraphcard from "../Gradegraphcard/Gradegraphcard";
import Gradepercoursepagination from "../Gradepercoursepagination/Gradepercoursepagination";
import React, {useEffect, useState} from "react";
import "./Grading.css";
import useGrading from "../../hooks/useGrading";
import Descrptivegradegraphcard from "../DescriptiveGradeGraphCard/Descrptivegradegraphcard";
import Gradepercoursetable from "../Gradepercoursetable/Gradepercoursetable";
import {randomAverageGraphData} from "../../hooks/helpers";
import {getGradesPerCourseAPI} from "../../hooks/utils/grading";

const is_ai_enabled = process.env.REACT_APP_IBL_ENABLE_AI !== 'false';

const Grading = (props) => {
    const {
        /*gradesPerCourse,
        gradesPerCourseLoaded,*/
        averageCourseGrade,
        averageCourseGradeLoaded,
        predictedAverageGrade,
        predictedAverageGradeLoaded

    } = useGrading()

    /*const [tempLoadedState, setTempLoadedState] = useState(false)

    useEffect(() => {
        setTimeout(()=>{
            setTempLoadedState(true)
        }, 3000)
    }, []);*/
        return (
    <div>
      <div className="p-row r-r skill">
          <Descrptivegradegraphcard
              data={averageCourseGrade}
              dataLoaded={averageCourseGradeLoaded}
              title={"Average Course Grade"}
          />
          {
              is_ai_enabled &&  <Descrptivegradegraphcard
                  data={predictedAverageGrade}
                  dataLoaded={predictedAverageGradeLoaded}
                  title={"Predicted Average Course Grade"}
                  isPredictive={true}
              />
          }

      </div>
        <Gradepercoursetable endPoint={getGradesPerCourseAPI}></Gradepercoursetable>

    </div>
  );
};

export default Grading;
