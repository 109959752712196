import { useEffect, useState } from 'react';
import {api, GLOBAL_COLORS, useIblTranslation} from '@iblai/ibl-web-react-common';
import './App.css';

import '@iblai/ibl-web-react-common/index.css';
import route from './routes';
import { AppDataContext, isJSON } from '@iblai/ibl-web-react-common';
import { RouterProvider } from 'react-router-dom';
import './i18n';
import {createGlobalStyle} from "styled-components";

//window.api = api

const initiateAppContextData = () => {
  return {
    ...localStorage,
    userData: isJSON(localStorage['userData'])
      ? JSON.parse(localStorage['userData'])
      : null,
    ibl_user_public_metadata: isJSON(
      localStorage.getItem('ibl_user_public_metadata')
    )
      ? JSON.parse(localStorage.getItem('ibl_user_public_metadata'))
      : null,
    userMetaData: {},
  };
};

function App() {
  const [appData, setAppData] = useState(initiateAppContextData());
  useIblTranslation();
  useEffect(() => {
    api.initiateAxdClientsWithToken(appData.axd_token);
  }, []);

  return (
    <>
      <GlobalStyles/>
      <AppDataContext.Provider value={{ appData, setAppData }}>
        <RouterProvider router={route(appData, setAppData)} />
      </AppDataContext.Provider>
    </>
  );
}

const GlobalStyles = createGlobalStyle`
  html {
    --grey: ${GLOBAL_COLORS.grey};
    --font: ${GLOBAL_COLORS.font};
    --accent: ${GLOBAL_COLORS.primary};
    --accenthover: ${GLOBAL_COLORS.secondary};
    --accent-2: ${GLOBAL_COLORS.tertiary};
    --gainsboro: ${GLOBAL_COLORS.grey_secondary};
    --white-smoke: ${GLOBAL_COLORS.white_smoke};
    --white: white;
    --black: black;
    --3: white;
  }
`;

export default App;
