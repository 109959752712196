import { api } from '@iblai/ibl-web-react-common';
import { getTenant, determineInputType } from '../helpers';
import { orgSearch } from '@iblai/ibl-web-react-common';
import { actionTypes } from '../../views/content/InvitationsViewContent/InvitationsReducer';

async function fetchCourseInvitations(platform_key, page, page_size) {
  return new Promise((resolve, reject) => {
    api.ibldmplatform.fetchCourseInvitations(
      { platform_key, page, page_size },
      function (data) {
        resolve(data);
      },
      function (error) {
        reject(error);
      }
    );
  });
}

function aggregateResults(results) {
  let aggregatedResults = [];
  let totalCount = 0;
  let nextPage = null;
  let previousPage = null;

  results.forEach((result) => {
    if (result.status === 'fulfilled' && result.value) {
      const data = result.value;
      totalCount += data.count;
      aggregatedResults = aggregatedResults.concat(data.results);

      if (data.next_page !== null) {
        nextPage =
          nextPage === null
            ? data.next_page
            : Math.max(nextPage, data.next_page);
      }
      if (data.previous_page !== null) {
        previousPage =
          previousPage === null
            ? data.previous_page
            : Math.min(previousPage, data.previous_page);
      }
    }
  });

  return {
    count: totalCount,
    next_page: nextPage,
    previous_page: previousPage,
    results: aggregatedResults,
  };
}

async function fetchProgramInvitation(platform_key, page, page_size) {
  return new Promise((resolve, reject) => {
    api.ibldmplatform.fetchProgramInvitations(
      { platform_key, page, page_size },
      function (data) {
        resolve(data);
      },
      function (error) {
        reject(error);
      }
    );
  });
}

async function fetchData(fetchFunction, callback, size, page) {
  const mainPromise = fetchFunction('main', page, size);
  let promises = [mainPromise];

  if (getTenant() !== 'main') {
    const getTenantPromise = fetchFunction(getTenant(), page, size);
    promises.push(getTenantPromise);
  }

  const results = await Promise.allSettled(promises);
  const aggregatedData = aggregateResults(results);
  callback(aggregatedData);
}

export function getPlatformInvitations(callback, size = 10, page = 1) {
  api.ibldmplatform.fetchPlatformInvitations(
    { platform_key: getTenant, page: page, page_size: size },
    function (data) {
      callback(data);
    }
  );
}

export async function getCourseInvitations(callback, size = 10, page = 1) {
  await fetchData(fetchCourseInvitations, callback, size, page);
}

export async function getProgramInvitation(callback, size = 10, page = 1) {
  await fetchData(fetchProgramInvitation, callback, size, page);
}

export function postInvite(data, callback) {
  if (data.invitationData && data.invitationData.length > 0) {
    let bulkData = {
      invitation_data: data.invitationData,
    };

    if (data.csvType === 'platform') {
      bulkData.platform_key = getTenant();
      api.ibldmplatform.createPlatformBulkInvitations(
        bulkData,
        function (data) {
          callback(data);
        }
      );
    } else if (data.csvType === 'course') {
      bulkData.course_id = '';
      api.ibldmplatform.createCourseBulkInvitations(bulkData, function (data) {
        callback(data);
      });
    } else if (data.csvType === 'program') {
      bulkData.program_key = '';
      api.ibldmplatform.createProgramBulkInvitations(bulkData, function (data) {
        callback(data);
      });
    }
    return;
  }

  const userIdentifier = determineInputType(data.userNameOrEmail);
  const inputs = {
    platform_key: getTenant(),
    ...userIdentifier,
  };

  if (data.numberOfInvites) {
    inputs.source = Date.now();
    inputs.count = parseInt(data.numberOfInvites);
    data.numberOfInvites = parseInt(data.numberOfInvites);
    api.ibldmplatform.createPlatformBulkInvitations(inputs, function (data) {
      callback(data);
    });
    return;
  }

  if (data.type === 'ibl-dm.public.dl_catalog_app_course') {
    inputs.course_id = data.id;
    api.ibldmplatform.createCatalogInvitationsCourse(inputs, function (data) {
      callback(data);
    });
  } else if (data.type === 'ibl-dm.public.dl_catalog_app_program') {
    inputs.program_key = 'program-v1:' + 'main' + '+' + data.id;
    api.ibldmplatform.createCatalogInvitationsProgram(inputs, function (data) {
      callback(data);
    });
  } else if (!data.type) {
    api.ibldmplatform.createCatalogInvitationsPlatform(inputs, function (data) {
      callback(data);
    });
  }
}

export function getCourseProgram(search = '', callback) {
  const data =
    '?multiple_indices_context=' +
    '[{"_index": "ibl-dm.public.dl_catalog_app_course"},{"_index": "ibl-dm.public.dl_catalog_app_program"}]' +
    '&context=' +
    search;
  orgSearch(data, function (data) {
    callback(data);
  });
}

const apiFunctionMapping = {
  Platform: {
    Single: api.ibldmplatform.createCatalogInvitationsPlatform,
    Seats: api.ibldmplatform.createPlatformBlankInvitations,
    Bulk: api.ibldmplatform.createPlatformBulkInvitations,
    Redeem: api.ibldmplatform.redeemPlatformInvitations,
  },
  Course: {
    Single: api.ibldmplatform.createCatalogInvitationsCourse,
    Seats: api.ibldmplatform.createCourseBlankInvitations,
    Bulk: api.ibldmplatform.createCourseBulkInvitations,
    Redeem: api.ibldmplatform.redeemCourseInvitations,
  },
  Program: {
    Single: api.ibldmplatform.createCatalogInvitationsProgram,
    Seats: api.ibldmplatform.createProgramBlankInvitations,
    Bulk: api.ibldmplatform.createProgramBulkInvitations,
    Redeem: api.ibldmplatform.redeemProgramInvitations,
  },
};

const handleInvitation = (type, mode, payload, callback, data = {}) => {
  switch (mode) {
    case 'Single':
    case 'Redeem':
      data = { ...data, ...determineInputType(payload) };
      break;
    case 'Seats':
      data = { ...data, count: parseInt(payload), source: getTenant() };
      break;
    case 'Bulk':
      data = payload;
      break;
    default:
      console.error('Invalid invitation mode');
      return;
  }

  const apiFunction = apiFunctionMapping[type][mode];
  if (apiFunction) {
    apiFunction(JSON.stringify(data), callback, callback);
  } else {
    console.error(
      `API function not defined for type: ${type} and mode: ${mode}`
    );
  }
};

const getInvitationDataType = (state) => {
  let payload = '';
  let invitationMode = '';
  let invitationType = '';
  let data = {};

  const invitationSource = state.isRedeemActive
    ? state.activeTableInvitationType
    : state.invitationsTab.activeTab;

  switch (invitationSource) {
    case 'Platform':
      invitationType = 'Platform';
      data = {
        ...data,
        platform_key: state.selectedItemID.trim(),
        ...(state.isRedeemActive ? { source: getTenant() } : {}),
      };
      break;
    case 'Course':
      invitationType = 'Course';
      data = {
        ...data,
        course_id: state.selectedItemID.trim(),
        ...(state.isRedeemActive ? { source: getTenant() } : {}),
      };
      break;
    case 'Program':
      invitationType = 'Program';
      data = {
        ...data,
        program_key: addProgramPrefix(state.selectedItemID.trim(), getTenant()),
        ...(state.isRedeemActive ? { source: getTenant() } : {}),
      };
      break;
    default:
      window.alert('Invalid Invitation Type');
      return null;
  }

  if (state.isRedeemActive) {
    payload = state.userNameOrEmail;
    invitationMode = 'Redeem';
    return { payload, invitationMode, invitationType, data };
  }

  switch (state.invitationsTab.activeInvitationMode) {
    case 'Single':
      payload = state.userNameOrEmail.trim();
      invitationMode = 'Single';
      break;
    case 'Bulk':
      payload = {
        platform_key: state.platformKey.trim(),
        ...state.bulkCsvData,
      };
      invitationMode = 'Bulk';
      break;
    case 'Seats':
      payload = Number(state.numberOfInvites);
      invitationMode = 'Seats';
      break;
    default:
      window.alert('Please fill all required fields');
      return null;
  }

  return { payload, invitationMode, invitationType, data };
};

export function postCreateOrRedeemInvitations(state, callback) {
  const invitationData = getInvitationDataType(state);

  if (invitationData) {
    const { payload, invitationMode, invitationType, data } = invitationData;
    if (payload && invitationMode && invitationType) {
      handleInvitation(invitationType, invitationMode, payload, callback, data);
    }
  }
}

export function extractInvitationCsvData(selectedFile, state, dispatch) {
  if (selectedFile) {
    const reader = new FileReader();

    reader.onload = function (e) {
      const content = e.target.result;
      const lines = content
        .split(/[\r\n]+/)
        .filter((line) => line.trim() !== '');

      // Determine the separator (comma or semicolon)
      const separator = lines[0].includes(';') ? ';' : ',';

      // Determine the type of CSV
      const isCourseCSV = lines[0].includes('course');
      const isProgramCSV = lines[0].includes('program');
      const isPlatformCSV = lines[0].includes('platform');

      if (!isCourseCSV && !isProgramCSV && !isPlatformCSV) {
        window.alert(
          'The CSV file does not contain course, program, or platform data.'
        );
        return;
      }

      const parsedData = lines.slice(1).map((line) => {
        const [userIdentifier, id] = line.split(separator);
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const entry = {};

        if (emailRegex.test(userIdentifier)) {
          entry.email = userIdentifier;
        } else {
          entry.username = userIdentifier;
        }

        if (isCourseCSV) {
          entry.course_id = id;
        } else if (isProgramCSV) {
          entry.program_key = addProgramPrefix(id, getTenant());
        } else if (isPlatformCSV) {
          entry.platform_key = id;
        }

        return entry;
      });
      dispatch({
        type: actionTypes.SET_FIELD,
        field: 'bulkCsvData',
        value: { ...state.bulkCsvData, invitation_data: parsedData },
      });
    };

    reader.onerror = function (e) {
      console.error('Error reading file', e);
    };

    reader.readAsText(selectedFile);
  }
}

export function isInvitationResponseError(data) {
  if (data?.status === 500) {
    const errorMessage =
      data?.responseJSON?.error_text || 'Something went wrong';
    window.alert(`Error: ${errorMessage}`);
    return true;
  }

  if (data?.status === 403) {
    const errorMessage = data?.responseJSON?.detail || 'Something went wrong';
    window.alert(`Error: ${errorMessage}`);
    return true;
  }
}

function addProgramPrefix(inputString, dynamicPart) {
  const prefix = `program-v1:${dynamicPart}+`;

  // Check if the prefix already exists in the inputString
  if (!inputString.startsWith(prefix)) {
    return prefix + inputString;
  }
  return inputString;
}
