export const invitationReducer = (state, action) => {
  switch (action.type) {
    case 'SET_FIELD':
      return { ...state, [action.field]: action.value };
    case 'TOGGLE_INVITE_USER':
      return { ...state, isInviteUserActive: !state.isInviteUserActive };
    case 'TOGGLE_REDEEM':
      return { ...state, isRedeemActive: !state.isRedeemActive };
    case actionTypes.RESET_INVITATION_STATE:
      return {
        ...state,
        selectedItemID: '',
        platformKey: '',
        userNameOrEmail: '',
        numberOfInvites: 0,
        bulkCsvData: {
          invitation_data: [],
        },
        invitationsTab: {
          activeTab: 'Platform',
          activeInvitationMode: 'Single',
        },
        resetFileInput: true,
      };
    case actionTypes.CLEAR_RESET_FILE_INPUT_FLAG:
      return {
        ...state,
        resetFileInput: false,
      };
    case 'SET_PLATFORM_AVAILABLE_INVITES':
      return {
        ...state,
        platformInvitationMetaData: {
          ...state.platformInvitationMetaData,
          availableInvites: action.value,
        },
      };
    case 'SET_PLATFORM_INVITATION_META_DATA':
      return {
        ...state,
        platformInvitationMetaData: {
          ...state.platformInvitationMetaData,
          ...action.value,
        },
      };
    case 'SET_PAGINATION_FIELD':
      return {
        ...state,
        platformInvitationMetaData: {
          ...state.platformInvitationMetaData,
          pagination: {
            ...state.platformInvitationMetaData.pagination,
            [action.field]: action.value,
          },
        },
      };
    default:
      return state;
  }
};

export const actionTypes = {
  TOGGLE_INVITE_USER: 'TOGGLE_INVITE_USER',
  TOGGLE_REDEEM: 'TOGGLE_REDEEM',
  SET_FIELD: 'SET_FIELD',
  SET_PLATFORM_AVAILABLE_INVITES: 'SET_PLATFORM_AVAILABLE_INVITES',
  SET_PLATFORM_INVITATION_META_DATA: 'SET_PLATFORM_INVITATION_META_DATA',
  SET_PAGINATION_FIELD: 'SET_PAGINATION_FIELD',
  RESET_INVITATION_STATE: 'RESET_INVITATION_STATE',
  CLEAR_RESET_FILE_INPUT_FLAG: 'CLEAR_RESET_FILE_INPUT_FLAG',
};
