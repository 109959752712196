import Descriptivelinechart from "../Descriptivelinechart/Descriptivelinechart";
import Table from "../Table/Table";
import Linechartgraph from "../Linechartgraph/Linechartgraph";
import Credentialspercoursepagination from "../Credentialspercoursepagination/Credentialspercoursepagination";
import Coursetable from "../Coursetable/Coursetable";
import Predictivedatepills from "../Predictivedatepills/Predictivedatepills";
import Credentialspercourserow from "../Credentialspercourserow/Credentialspercourserow";
import Descriptivedatepills from "../Descriptivedatepills/Descriptivedatepills";
import Predictivelinechart from "../Predictivelinechart/Predictivelinechart";
import React, {useEffect, useState} from "react";
import "./Credentials.css";
import {randomLineChartGraphData} from "../../hooks/helpers";
import DescriptiveLineChartGraph from "../DescriptiveLineChartGraph/DescriptiveLineChartGraph";
import useCredentials from "../../hooks/useCredentials";
import {getCredentialsPerCourse} from "../../hooks/utils/credentials";

const Credentials = (props) => {

    const {
        credentialsOverTime,
        credentialsOverTimeLoaded,
        /*credentialsPerCourseLoaded,
        credentialsPerCourse*/
    } = useCredentials()

    return (
        <div
            react-hook="useCredentials"
            react-hook-return="courseCredentialsIssuedOverTimeTotal,courseCredentialsIssuedOverTimeLoaded,courseCredentialsIssuedOverTimeChangeClass,courseCredentialsIssuedOverTimeChangeSign,courseCredentialsIssuedOverTimeNumericalChange,courseCredentialsIssuedOverTimePercentageChange,courseCredentialsIssuedOverTimeChangeSymbol,courseCredentialsIssuedOverTimeChartData,predictedCourseCrededialsOverTimeTotal,predictedCourseCrededialsOverTimeLoaded,predictedCourseCrededialsOverTimeChangeClass,predictedCourseCrededialsOverTimeChangeSign,predictedCourseCrededialsOverTimeNumericalChange,predictedCourseCrededialsOverTimePercentageChange,predictedCourseCrededialsOverTimeChangeSymbol,predictedCourseCrededialsOverTimeChartData,registeredUsersPerCourseTotal,registeredUsersPerCourseLoaded,registeredUsersPerCourseChangeClass,registeredUsersPerCourseChangeSign,registeredUsersPerCourseNumericalChange,registeredUsersPerCoursePercentageChange,registeredUsersPerCourseChangeSymbol,registeredUsersPerCoursetData,credentialsPerCourseTotal,credentialsPerCourseLoaded,credentialsPerCourseChangeClass,credentialsPerCourseChangeSign,credentialsPerCourseNumericalChange,credentialsPerCoursePercentageChange,credentialsPerCourseChangeSymbol,credentialsPerCourseData"
        >
            <div className="p-row r-r">
                <div className="div-block-204 l" style={{width:"100%"}}>
                    <DescriptiveLineChartGraph
                        data={credentialsOverTime}
                        dataLoaded={credentialsOverTimeLoaded}
                        title={"Course Credentials Issued Over Time"}
                        index={1}
                    />
                </div>
                {/*<div className="div-block-204 r">
                    <DescriptiveLineChartGraph
                        data={randomLineChartGraphData()}
                        dataLoaded={tempLoadedState}
                        title={"Predicted Course Credentials Over Time"}
                        index={2}
                        isPredictive={true}
                    />
                </div>*/}
            </div>
            <Coursetable useSizeDropdown={false}
                tableHeaders={['#', "Courses", "Credentials Issued", "Percentage of Learners Who Earned Credentials"]}
                title={"Credentials Per Course"} endPoint={getCredentialsPerCourse} dataKey={"results"}></Coursetable>
        </div>
    );
};

export default Credentials;
