import { api } from '@iblai/ibl-web-react-common';
import { beginningDateRange, getTenant } from '../helpers';

export function getEngagementTimeSpentPerCourseAPI(additionalData, callback) {
  api.iblaxdApiCallWrapper(
    (args) => api.iblaxdengagement.engagementOrgsTimePerCourseRetrieve(...args),
    getTenant(),
    { ...additionalData }
  )((err, data, response) => (err, callback(data), response));
}

export function getEngagementTimeSpentOvertimeAPI(callback) {
  api.iblaxdApiCallWrapper(
    (args) => api.iblaxdengagement.engagementOrgsTimeOverTimeRetrieve(...args),
    getTenant(),
    beginningDateRange
  )((err, data, response) => (err, callback(data), response));
}
