import {useEffect, useState} from 'react';
import {
    getEnrollmentsOverTimeAPI,
    getEnrollmentsPerCourseAPI,
} from './utils/enrollments';  // Replace with the correct path

const useEnrollment = () => {
    const [enrollmentsOverTime, setEnrollmentsOverTime] = useState([]);
    const [enrollmentsOverTimeLoaded, setEnrollmentsOverTimeLoaded] = useState(false);

    /*const [enrollmentsPerCourse, setEnrollmentsPerCourse] = useState([]);
    const [enrollmentsPerCourseLoaded, setEnrollmentsPerCourseLoaded] = useState(false);*/

    useEffect(() => {
        getEnrollmentsOverTimeAPI((data) => {
            setEnrollmentsOverTime(data);
            setEnrollmentsOverTimeLoaded(true);
        });

        // Assuming you have a variable for the current page in your component
        /*const currentPage = 1;  // Replace with your logic to get the current page

        getEnrollmentsPerCourseAPI(currentPage, (data) => {
            setEnrollmentsPerCourse(data);
            setEnrollmentsPerCourseLoaded(true);
        });*/
    }, []);

    return {
        enrollmentsOverTime,
        enrollmentsOverTimeLoaded,
        /*enrollmentsPerCourse,
        enrollmentsPerCourseLoaded,*/
    }


};

export default useEnrollment;
